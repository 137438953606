import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import LoginLayout from "./layouts/LoginLayout";

import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Stock from './pages/Stock';
import PPTejido from './pages/PPTejido';
import PPHTejido from './pages/PPHTejido';
import PPPostePlaca from './pages/PPPostePlaca';
import PPHPostePlaca from './pages/PPHPostePlaca';
import Production from './pages/Production';
import MinimumStockExcedeed from './pages/MinimumStockExcedeed';
import Employee from './pages/Employee';
import PasswordUpdate from './pages/PasswordUpdate';
import TransportProducts from './pages/TransportProducts';
import ConfirmRemitos from './pages/ConfirmRemitos';
import Export from './pages/Export';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LoginLayout/>,
      children: [
        { path: '/', element: <Login/> },
        { path: '404', element: <NotFound/> },
        { path: '*', element: <Navigate to="/404"/> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        { path: 'app', element: <DashboardApp/> },
        { path: 'stock', element: <Stock/> },
        { path: 'export', element: <Export/> },
        { path: 'production', element: <Production/> },
        { path: 'minimun-stock-excedeed', element: <MinimumStockExcedeed/> },
        { path: 'pp-tejido', element: <PPTejido/> },
        { path: 'pp-poste-placa', element: <PPPostePlaca/> },
        { path: 'pp-history-poste-placa', element: <PPHPostePlaca/> },
        { path: 'pp-history-tejido', element: <PPHTejido/> },
        { path: 'transport-products', element: <TransportProducts/> },
        { path: 'confirm-remitos', element: <ConfirmRemitos/> },
        { path: 'employee', element: <Employee/> },
      ],
    },   
    {
      path: '/password/update',
      element: <PasswordUpdate/>,
    },
    { 
      path: '*',
      element: <Navigate to="/404" replace/> 
    },
  ]);
}
