
import { useState, useEffect, useCallback } from 'react';
import { Button, Card, Stack, Container, Typography, Alert } from '@mui/material';
import dayjs from 'dayjs';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import useResponsive from "../hooks/useResponsive";
import DetailsPPH from "../sections/production/DetailsPPH";
import WeekPickerModal from "../sections/production/WeekPickerModal";
import axios, { handleAxiosError } from '../config/axios';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import ProductColumns from '../data/table/columns/productsprocesshistorytejido';

export default function PPHTejido() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(null);  
  const [date, setDate] = useState(dayjs());  
  const [openModalWeekPicker, setOpenModalWeekPicker] = useState(false);  
  const [ listeningStream , setListeningStream ] = useState(false);
  const [ dataUpdateFields , setDataUpdateFields ] = useState([]);
  const [ detailsPP, setDetailsPP] = useState(null);
  const { token } = JSON.parse(window.localStorage.getItem("user"));
  
  const type = 'alambre';
  const isDesktop = useResponsive('up', 'lg');

  useEffect( () => {
    let events;
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        /* if(data.module === 'pp-tejido-total'){
          setDataUpdateFields(['STOCK_TOTAL', 'STOCK_TOTAL_FABRICA', 'STOCK_TOTAL_DOMENECH']);
          setDataUpdate(data);
        } */
        if(data.module === 'pp-tejido'){
          const start = date.startOf('week');
          const end = date.endOf('week');
          const dayIsBetween = dayjs(data.date).isBetween(start, end, null, '[]');        
          if(dayIsBetween){
            setDataUpdate(data.list);
          }
        }
        if(data.module === 'pp-tejido-add'){
            setDataUpdate(data);
        }
        if(data.module === 'pp-tejido-remove'){
          setDataUpdate(data.value);
        }
      };
      setListeningStream(true);
    }
    return () => {
      if(events)events.close();
    };
  }, [date, listeningStream, token]);

  useEffect(()=>{
    setListeningStream(false);
  },[date]);


  const onFetchProductsProcess = useCallback(() => {   
    handleModalWeekPickerClose();
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: `/production/history?date=${date.format('YYYY-MM-DD')}&type=${type}`,
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [date, token]);  
 
  useEffect(()=> {
    onFetchProductsProcess();
  }, [onFetchProductsProcess])

  const initializationDataUpdate = () => {
    setDataUpdate(null);
    setDataUpdateFields([]);
  }

  const handleModalWeekPickerClose = () => {
    setOpenModalWeekPicker(false);
  }; 

  const handleModalWeekPickerOpen = () => {
    setOpenModalWeekPicker(true);
  };

  const handleModalDetailsPPClose = () => {
    setDetailsPP(null);
  };

  const handlePP = (cell) => {
    const idProduct = cell.row.original.ID_PRODUCTO;
    const datePP = cell.column.columnDef.meta;
    const dayPP = cell.column.columnDef.accessorKey;
    const idPP = `${idProduct}_${datePP}`;
    setDetailsPP({ idProduct, datePP, dayPP, idPP });
  };
 
  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Historial Productos en Proceso: Tejido">
      <Container maxWidth="xl" disableGutters>  
        {openModalWeekPicker && 
          <WeekPickerModal 
            handleModalClose={handleModalWeekPickerClose} 
            openModal={openModalWeekPicker}
            setDate={setDate} 
            date={date}
            daysToMark
            urlDaysToMark={'/production/calendar/days'}
            token={token}
            type={type}
          />   
        }
        {!!detailsPP && 
         <DetailsPPH
          openModal={!!detailsPP}
          handleModalClose={handleModalDetailsPPClose}         
          detailsPP={detailsPP}
         />     
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" >
          <Typography variant="h4" gutterBottom textAlign={{xs: "center"}}>
            Historial {isDesktop ? "Productos en Proceso" : "PP"}: Tejido
          </Typography>               
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>  
          <Button variant="outlined" onClick={handleModalWeekPickerOpen} startIcon={<DateRangeRoundedIcon/>}>
            Cambiar semana
          </Button>                 
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
            
        <Card>     
          <Scrollbar>     
            <TableV2 
              columns={ProductColumns(products, date, handlePP)} 
              data={products}
              dataUpdate={dataUpdate}
              initializationDataUpdate={initializationDataUpdate}
              dataUpdateFields={dataUpdateFields}
              nameComponent="pp"
              enableTitle
              title={`Semana del ${date.startOf('week').format('DD/MM/YYYY')} al ${date.endOf('week').format('DD/MM/YYYY')}`}
            />      
          </Scrollbar>          
        </Card>     
      </Container>        
    </Page>   
    </>
  );
}
