
import { useState, useEffect, useCallback } from 'react';
import { Button, Card, Stack, Container, Typography, Alert, Snackbar } from '@mui/material';
import dayjs from 'dayjs';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import useResponsive from "../hooks/useResponsive";
import DetailsPPH from "../sections/production/DetailsPPH";
import WeekPickerModal from "../sections/production/WeekPickerModal";
import axios, { handleAxiosError } from '../config/axios';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import ProductColumns from '../data/table/columns/productsprocesshistoryposte';
import { daysWeek } from '../utils/helpers';


export default function PPHPostePlaca() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [products, setProducts] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(null);  
  const [date, setDate] = useState(dayjs());  
  const [openModalWeekPicker, setOpenModalWeekPicker] = useState(false);  
  const [ listeningStream , setListeningStream ] = useState(false);
  const [ dataUpdateFields , setDataUpdateFields ] = useState([]);
  const [ detailsPP, setDetailsPP] = useState(null);
  const [ merma, setMerma ] = useState(null);
  const [ percentageMerma, setPercentageMerma ] = useState(null);
  const { token } = JSON.parse(window.localStorage.getItem("user"));
  
  const type = 'poste_placa';
  const isDesktop = useResponsive('up', 'lg');

  useEffect( () => {
    let events;
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.module === 'pp-posteplaca'){
          const start = date.startOf('week');
          const end = date.endOf('week');
          const dayIsBetween = dayjs(data.date).isBetween(start, end, null, '[]');        
          if(dayIsBetween){
            setDataUpdate(data.list);
          }
        }
        if(data.module === 'pp-posteplaca-add'){
            setDataUpdate(data);
        }
        if(data.module === 'pp-posteplaca-remove'){
          setDataUpdate(data.value);
        }
      };
      setListeningStream(true);
    }
    return () => {
      if(events)events.close();
    };
  }, [date, listeningStream, token]);

  useEffect(()=>{
    setListeningStream(false);
  },[date]);

  const onFetchProductsProcess = useCallback(() => {   
    handleModalWeekPickerClose();
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: `/production/history?date=${date.format('YYYY-MM-DD')}&type=${type}`,
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [date, token]);  

  const onFetchMerma = useCallback(() => {   
    handleModalWeekPickerClose();
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: `/merma/${date.format('YYYY-MM-DD')}`,
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setMerma(data.data.data[0]);
          setPercentageMerma(data.data.data[0].MERMA);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [date, token]);  
 
  useEffect(()=> {
    onFetchProductsProcess();
    onFetchMerma();
  }, [onFetchMerma, onFetchProductsProcess])

  const onFetchSaveMerma = (data) => {   
    setIsLoading(true);
    const instance = { 
        method: 'put', 
        url: `/merma/${date.format('YYYY-MM-DD')}`,
        headers: {
            "x-auth-token": token,
        },
        data,
    };
    axios(instance).then((data) => {
        setIsLoading(false);
        if(data.data.ok){  
          setOpenSuccess({open: true, msg: "Se guardo los datos de merma correctamente"});
        } else {
          setError(data.data.error);
        }
    })
    .catch((e) => {           
        setError(handleAxiosError(e));
        setIsLoading(false);
    })
};

const handleSuccess = (event, reason) => {
  if (reason === 'clickaway') return;    
  setOpenSuccess({open: false, msg: ""});
};  

  const initializationDataUpdate = () => {
    setDataUpdate(null);
    setDataUpdateFields([]);
  }

  const handleModalWeekPickerClose = () => {
    setOpenModalWeekPicker(false);
  }; 

  const handleModalWeekPickerOpen = () => {
    setOpenModalWeekPicker(true);
  };

  const handleModalDetailsPPClose = () => {
    setDetailsPP(null);
  };

  const handlePP = (cell) => {
    const idProduct = cell.row.original.ID_PRODUCTO;
    const datePP = cell.column.columnDef.meta;
    const dayPP = cell.column.columnDef.accessorKey;
    const idPP = `${idProduct}_${datePP}`;
    setDetailsPP({ idProduct, datePP, dayPP, idPP });
  };

  const handleMerma = (value) => {
    setPercentageMerma(value)
    if(value >= 0 && value <= 100){
      const newMerma = merma;
      daysWeek.forEach((day)=> {
        const total = products.reduce((acc, curr) => acc + curr[day], 0)
        newMerma[day] = Math.round((total * value) / 100);
      });
      setMerma(newMerma);      
    } 
  }

  const submitMerma = () => {
    if(typeof percentageMerma === "string" || (percentageMerma < 0 || percentageMerma > 100)){
      setError("La merma debe ser un numero de 0 a 100")
    } else {
      onFetchSaveMerma({
        merma: percentageMerma,
        lunes: merma.LUNES,
        martes: merma.MARTES,
        miercoles: merma.MIERCOLES,
        jueves: merma.JUEVES,
        viernes: merma.VIERNES,
        sabado: merma.SABADO,
        domingo: merma.DOMINGO
      }); 
    }
  }
 
  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Historial Productos en Proceso: Poste">
      <Container maxWidth="xl" disableGutters>  
        {openModalWeekPicker && 
          <WeekPickerModal 
            handleModalClose={handleModalWeekPickerClose} 
            openModal={openModalWeekPicker}
            setDate={setDate} 
            date={date}
            daysToMark
            urlDaysToMark={'/production/calendar/days'}
            token={token}
            type={type}
          />   
        }
        {!!detailsPP && 
         <DetailsPPH
          openModal={!!detailsPP}
          handleModalClose={handleModalDetailsPPClose}         
          detailsPP={detailsPP}
         />     
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" >
          <Typography variant="h4" gutterBottom textAlign={{xs: "center"}}>
            Historial {isDesktop ? "Productos en Proceso" : "PP"}: Poste
          </Typography>               
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>  
          <Button variant="outlined" onClick={handleModalWeekPickerOpen} startIcon={<DateRangeRoundedIcon/>}>
            Cambiar semana
          </Button>                 
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
            
        <Card>     
          <Scrollbar>     
            <TableV2 
              columns={ProductColumns(products, date, merma, percentageMerma, handlePP, handleMerma, submitMerma)} 
              data={products}
              dataUpdate={dataUpdate}
              initializationDataUpdate={initializationDataUpdate}
              dataUpdateFields={dataUpdateFields}
              nameComponent="pp"
              enableTitle
              title={`Semana del ${date.startOf('week').format('DD/MM/YYYY')} al ${date.endOf('week').format('DD/MM/YYYY')}`}
            />      
          </Scrollbar>          
        </Card>     
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>        
    </Page>   
    </>
  );
}
