import { Box, Paper, TextField, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState  } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";
import Label from '../../components/Label';

MinimumAvailable.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    idProduct: PropTypes.number,
    units: PropTypes.number,
};

export default function MinimumAvailable(props) {
    const {handleModalClose,openModal,refresh,idProduct,units} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const validationSchema = Yup.object().shape({
        id_producto: Yup.number().integer().positive().min(1).max(99999).required('El código es requerido').default(idProduct ?? ''),
        stock_minimo_disponible: Yup.number().positive().min(0.1).max(99999999.9).required('La cantidad de stock minimo disponible es requerida'),  
    });
    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { token } = JSON.parse(window.localStorage.getItem("user"));
  
    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = (data) => {
        onFetchModify(data);
        reset();
    };

    const onFetchModify = (data) => {    
        setIsLoading(true);
        const instance = { 
            method: 'patch', 
            url: `/product/minimo/${idProduct}`,
            headers: {
            "x-auth-token": token,
            },
            data,
        };
        axios(instance).then((data) => {
            if(data.data.ok){  
                refresh();  
            } else {
                setError({open: true, title: 'No se pudo actualizar el stock minimo disponible', msg: data.data.error});
            }
            setIsLoading(false);
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo actualizar el stock minimo disponible', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };

    const handleLimit = (e) => {
        const maxLength = 8;
        if(e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }
    };  

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'sm'} onBackdropClick={handleModalClose}>
        <DialogTitle>
        <Grid container spacing={0} >
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary' }}>
                        Actualizar Stock Minimo Disponible
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={1} py={0}>                
                    <Grid container spacing={1}>   
                        
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="stock_minimo_disponible"
                                name="stock_minimo_disponible"
                                label="Stock Minimo Disponible"
                                fullWidth
                                type="number"
                                margin="dense"
                                onInput={handleLimit}
                                {...register('stock_minimo_disponible')}
                                error={!!errors.stock_minimo_disponible}
                            />
                        </Grid>   
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>
            <Grid container mr={3} ml={3} mb={1}>
                <Grid item xs={12} sm={3} align="left"> 
                    <Label
                        variant="filled"
                        color="default"
                        sx={{
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            width: 100,
                        }}
                    >
                        Código {idProduct}
                    </Label>
                </Grid>
                <Grid item xs={12} sm={3} align="left"> 
                    <Label
                        variant="filled"
                        color="default"
                        sx={{
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            width: 100,
                        }}
                    >
                        {(units)? `Actual ${units}` : 'Sin definir'}
                    </Label>
                </Grid>
                <Grid item xs={12} sm={6} align="right"> 
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={handleSubmit(onSubmit)} >
                        Actualizar
                    </Button>
                    <Button variant="outlet" color="primary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>
        </DialogActions>             
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    </Dialog> 
    );
}
