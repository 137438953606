import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import 'dayjs/locale/es';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import axios from '../config/axios';

dayjs.extend(isBetweenPlugin);
dayjs.locale('es');

WeekPicker.propTypes = {
    setDate: PropTypes.func.isRequired,
    date: PropTypes.object.isRequired,
    daysToMark: PropTypes.bool,
    urlDaysToMark: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string.isRequired,
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));


export default function WeekPicker(props) {
  const theme = useTheme();
  const requestAbortController = useRef(null);
  const [highlightedDays, setHighlightedDays] = useState([]);

  const fetchHighlightedDays = (date) => {
    const instance = { 
      method: 'get', 
      url: `${props.urlDaysToMark}?date=${date.format('YYYY-MM-DD')}&day=true&type=${props.type}`,
      headers: {
        "x-auth-token": props.token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){     
          setHighlightedDays(data.data.data);
        } 
      })  
  }
  
  useEffect(() => {
    fetchHighlightedDays(props.date);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);
  
  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!props.date) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = props.date.startOf('week');
    const end = props.date.endOf('week');

    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');
    const isSelected = props.daysToMark && !pickersDayProps.outsideCurrentMonth && highlightedDays.indexOf(date.date()) >= 0;
    return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          sx={{
            fontWeight: (isSelected) ? 'bold' : '',
            border: (isSelected) ? 1 : 0,
            borderColor: (isSelected) ? theme.palette.primary.main : '',
            
          }}
        />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Calendario por semana"
        value={props.date}
        onChange={(newValue) => {
            props.setDate(newValue);
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="'Week of' MMM d"
        onMonthChange={(props.daysToMark) ? handleMonthChange : null}
      />
    </LocalizationProvider>
  );
}
