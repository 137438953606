import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function LoginLayout() {
  const { user } = useAuth();
  
  if (user) {
    if(user.actualizar_password){
      return <Navigate to="/password/update"/>;
    } 
    return <Navigate to="/dashboard/stock"/>;    
  }

  return (
    <div>    
      <Outlet />
    </div>
  )
};