import { Box, Paper, TextField, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";

ConcreteMachining.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    success: PropTypes.func.isRequired,
};

export default function ConcreteMachining(props) {
    const {success, handleModalClose,openModal} = props;
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});

    const validationSchema = Yup.object().shape({
        cantidad: Yup.number().integer().positive().min(1).max(9999).required('La cantidad de maquinada/s de hormigon es requerida'),
     });
    const { register, clearErrors, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });    
    const { token } = JSON.parse(window.localStorage.getItem("user"));

    const maximumFieldLength = {
        cantidad: 4,
    };   

    const handleLimit = (e) => {
        const maxLength = maximumFieldLength[e.target.id];
        if(e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }
    };      

    const handleOnInput = (e) => {
        handleLimit(e)
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = (data) => {
        onFetchLoadConcreteMachining(data);
        clearErrors();
    };

    const onFetchLoadConcreteMachining = (data) => {    
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: `/production/concrete_machining`,
            headers: {
                "x-auth-token": token,
            },
            data
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo cargar la/s maquinada/s de hormigon', msg: data.data.error});
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo cargar la/s maquinada/s de hormigon', msg: handleAxiosError(e)});           
            setIsLoading(false);
        })
    };

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'sm'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Maquinada/s de hormigon   
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={0}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        required
                                        id="cantidad"
                                        name="cantidad"
                                        label="Cantidad"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        onInput={handleOnInput}
                                        {...register('cantidad')}
                                        error={!!errors.cantidad}
                                    />
                                </Grid>                           
                            </Grid>   
                        </Grid>                            
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={1}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary"  disabled={isLoading} onClick={handleSubmit(onSubmit)} >
                        Cargar
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>          
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
