import { Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";

RemovePP.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    removedProductId: PropTypes.number,
    success: PropTypes.func.isRequired,
};

export default function RemovePP(props) {
    const {success,handleModalClose,openModal,removedProductId} = props;
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});

    const { token } = JSON.parse(window.localStorage.getItem("user"));

      const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const handleRemoveProduct = () => {
        onFetchRemove();
    };

    const onFetchRemove = () => {    
        setIsLoading(true);
        const instance = { 
            method: 'patch', 
            url: `/production/remove/${removedProductId}`,
            headers: {
                "x-auth-token": token,
            },
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo quitar el producto de la lista', msg: data.data.error});
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo quitar el producto de la lista', msg: handleAxiosError(e)});           
            setIsLoading(false);
        })
    };

    return (
        <>
            <Dialog
                open={openModal}
                onClose={() => handleModalClose}
                fullWidth
                maxWidth={'md'}
                onBackdropClick={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar producto"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"¿Esta seguro que desea eliminar el producto de la lista de productos en proceso?. No debe tener productos en proceso para poder ser quitado."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" disabled={isLoading} onClick={handleRemoveProduct}>
                        Eliminar
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={error.open}
                onClose={handleCloseError}
                onBackdropClick={handleCloseError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {error.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleCloseError}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
   );
}
