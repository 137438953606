import { Box, Paper, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import StockTransferColumns from '../../data/table/columns/stocktransfer';

import axios, { handleAxiosError } from "../../config/axios";

TransferStockForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    transferToStock:  PropTypes.arrayOf(PropTypes.object).isRequired,
    success: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default function TransferStockForm(props) {
    const {success, handleModalClose,openModal,transferToStock, type} = props;
    
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    const { token } = JSON.parse(window.localStorage.getItem("user"));       

    useEffect(() => {  
        if(transferToStock.length < 1){
            setError({open: true, title: 'No se puede traspasar stock', msg: 'Debe marcar unidades en proceso poder traspasarlas al stock'});
        }
    }, [transferToStock]);

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
        handleModalClose();
    };

    const onFetchTransferToStock = () => {   
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: `/production/transfer?type=${type}`,
            headers: {
                "x-auth-token": token,
            },
            data: { pps: transferToStock.map(pp => ({
                id_producto: pp.ID_PRODUCTO,
                fecha_inicio_produccion: pp.FECHA_INICIO_PRODUCCION,
                cantidad: pp.A_TRASPASAR,
                id_empleado: pp.ID_EMPLEADO,
            })) },
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                handleModalClose();
                success();
            } else {
                setError({open: true, title: 'No se pudo traspasar stock', msg: data.data.error});
            }
        })
        .catch((e) => {           
            setError({open: true, title: 'No se pudo traspasar stock', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };
    

    const onSubmit = () => {
        onFetchTransferToStock();
    };

    
    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Traspasar a Stock   
                    </Typography>                       
                </Grid>
                <Grid item xs={12} sm={1} align="right">                    
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={2}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >                                   
                                <Grid item xs={12} sm={12} mt={0}>
                                    <DataTable
                                        columns={StockTransferColumns()}
                                        data={transferToStock}
                                        disableColumnMenu
                                        disableToolbar
                                        disableFooter                                        
                                        styleHeader={{
                                            backgroundColor:theme.palette.primary.main, 
                                            color:"white",
                                        }}                
                                        height={250}     
                                    />
                                </Grid>                                
                            </Grid>   
                        </Grid>                      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={0}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={onSubmit} >
                        Traspasar a Stock
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>    
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
