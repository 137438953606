import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

LoadBackdrop.propTypes = {
    isLoading: PropTypes.bool,
};

export default function LoadBackdrop({isLoading}) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color='secondary'/>
        </Backdrop>
    );
}
