/* eslint-disable camelcase */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';  
import { Stack, TextField, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAuth } from "../../../hooks/useAuth";
import axios, { handleAxiosError } from '../../../config/axios';
import roles from '../../../config/roles';
import Iconify from '../../../components/Iconify';

 
export default function LoginForm() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('El nombre de usuario es requerido'),
    password: Yup.string().required('La contraseña es requerida'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },

    validationSchema: LoginSchema,

    onSubmit: () => {
      setIsSubmitting(true);
      const instance = { 
        method: 'post', 
        url: '/auth/login',
        data: {
          nombre_usuario: values.username,
          password: values.password,
        }
      };
      axios(instance)
      .then((res) => {
        if(res.data.ok){     
          const { nombre_usuario, nombre_completo, email, token, rol, actualizar_password } = res.data.data;     
          login({
            username: nombre_usuario,
            nombre_completo,
            email,
            token,
            actualizar_password,
            ...roles(rol),
          });
        }
      })
      .catch((e) => {
        const msg = handleAxiosError(e);
        setErrorLogin(msg);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
      
    },

  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Nombre de usuario"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Iniciar Sesión
        </LoadingButton>
        { errorLogin && <Alert severity="error" style={{marginTop: "20px"}} >{errorLogin}</Alert>}
      </Form>
    </FormikProvider>
  );
}
