export default () => [
  { 
    field: 'ID_PRODUCTO', 
    headerName: 'Id', 
    width: 10,
    description: 'Id del producto',
  }, 
  { 
    field: 'PRODUCTO', 
    headerName: 'Producto', 
    width: 300,
    description: 'Producto',
  }, 
  { 
    field: 'PRODUCTO_TIPO', 
    headerName: 'Tipo producto', 
    width: 130,
    description: 'Tipo de producto',
  }, 
  { 
    field: 'EMPLEADO', 
    headerName: 'Empleado', 
    width: 300,
    description: 'Productor',
  }, 
  { 
    field: 'EMPLEADO_TIPO', 
    headerName: 'Tipo empleado', 
    width: 130,
    description: 'Tipo de productor',
  }, 
  { 
    field: 'CANTIDAD', 
    headerName: 'Cantidad', 
    width: 120,
    type: 'number',
    description: 'Unidades producidas',
   },
  { 
   field: 'TRANSPORTAR', 
   headerName: 'Transportar', 
   width: 120,
   type: 'number',
   description: 'Unidades a trasportar a Domenech',
   sortable: false, 
   editable: true, 
   preProcessEditCellProps: (params) => {
     const hasError = params.props.value > params.row.CANTIDAD || params.props.value < 0;
     return { ...params.props, error: hasError };
   },
  },
];

