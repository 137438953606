export default () => [  
  { 
    field: 'NOMBRE_EMPLEADO', 
    headerName: 'Empleado', 
    width: 329,
    description: 'Empleado productor',
    sortable: false,
  },
  { 
    field: 'NOMBRE_PRODUCTO', 
    headerName: 'Producto', 
    width: 320,
    description: 'Producto',
    sortable: false,
  },
  { 
    field: 'CANTIDAD_NOMINAL', 
    headerName: 'Cantidad Nominal', 
    width: 150,
    type: 'number',
    description: 'Unidades que figuran en el remito interno',
    sortable: false,
  },
  { 
    field: 'CANTIDAD_REAL', 
    headerName: 'Cantidad Real', 
    width: 150,
    type: 'number',
    description: 'Unidades fisicas reales entregadas',
    sortable: false,
  },
  { 
    field: 'CANTIDAD_BAJA', 
    headerName: 'Descontado', 
    width: 150,
    type: 'number',
    description: 'Unidades dadas de baja',
    sortable: false,
  }, 
];
