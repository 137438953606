/* eslint-disable camelcase */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';  
import { Button, Stack, TextField, IconButton, InputAdornment, Alert, Typography, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAuth } from "../../../hooks/useAuth";
import axios, { handleAxiosError } from '../../../config/axios';
import Iconify from '../../../components/Iconify';

export default function PasswordForm() {
  const { logout } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modal, setModal] = useState({open: false, title: '', msg: ''});

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('La contraseña es requerida'),
    repeat_password: Yup.string().required('Debe repetir la contraseña'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      repeat_password: '',
    },

    validationSchema: LoginSchema,

    onSubmit: () => {
      setIsSubmitting(true);
      if(values.password === values.repeat_password){        
        const { token } = JSON.parse(window.localStorage.getItem("user"));
        const instance = { 
          method: 'patch', 
          url: '/password',
          headers: {
            "x-auth-token": token,
        },
        data: {
          password: values.password,
        }
        };
        axios(instance)
        .then((res) => {
          if(res.data.ok){  
            setModal({open: true, title: 'Actualización de contraseña exitosa', msg: 'Deberá ingresar nuevamente con la contraseña generada. Si tiene inconvenientes contacte al soporte.'});                    
          }
        })
        .catch((e) => {
          const msg = handleAxiosError(e);
          setError(msg);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      } else {
        setError('Debe repetir la contraseña correctamente!');
        setIsSubmitting(false);
      }
      
    },

  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword((show) => !show);
  };

  const handleModalClose = () => {
    setModal({open: false, title: '', msg: ''});
    logout(); 
};

  return (
      <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            <Typography variant="h4" align="center" margin="dense" sx={{ color: 'text.primary' }}>
                Genera una nueva contraseña 
            </Typography> 
            <Typography variant="body2" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 5, }}>
              La contraseña debe ser mayor o igual a 8 caracteres, no incluir espacios en blanco,
              incluir por lo menos 1 número, 1 carácter especial (- * ? ! @ # $ / () {} = . , ; :), 
              1 mayúscula y 1 minúscula.
            </Typography>            
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showRepeatPassword ? 'text' : 'password'}
              label="Repetir Contraseña"
              {...getFieldProps('repeat_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowRepeatPassword} edge="end">
                      <Iconify icon={showRepeatPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.repeat_password && errors.repeat_password)}
              helperText={touched.repeat_password && errors.repeat_password}
            />
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Cambiar contraseña
          </LoadingButton>
          { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}
        </Form>
      </FormikProvider>
      <Dialog
          open={modal.open}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              {modal.title}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {modal.msg}
              </DialogContentText>
          </DialogContent> 
          <DialogActions>
              <Button variant="contained" color="primary" onClick={handleModalClose}>
                  Aceptar
              </Button>
          </DialogActions>
      </Dialog>
      </>
  );
}
