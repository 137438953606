import { useState, useEffect } from 'react';
import { Navigate, useOutlet } from "react-router-dom";

import { styled } from '@mui/material/styles';
import { AlertTitle, Alert, Snackbar } from '@mui/material';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DialogGeneric from "../../components/DialogGeneric";

import { useAuth } from "../../hooks/useAuth";
import axios from '../../config/axios';

const APP_BAR_MOBILE = 30;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({open: false, title: '', msg: ''});
  const [openAlert, setOpenAlert] = useState({open: false, msg: '', title: ''});
  const [ listeningStream , setListeningStream ] = useState(false);
  const { user, logout } = useAuth();
  const outlet = useOutlet();
  const { username, token } = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    verify();
  }, []);

  useEffect( () => {
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe/notification/${username}?xauthtoken=${token}`;
      const events = new EventSource(url);

      events.onmessage = (event) => {
        const { title, msg } = JSON.parse(event.data);
        setOpenAlert({open: true, msg, title})
      };
      setListeningStream(true);
    }
  }, [listeningStream, token, username]);

  const verify = () => { 
    const instance = { 
      method: 'get', 
      url: '/auth/verify',
      headers: {
        "x-auth-token":  JSON.parse(window.localStorage.getItem("user")).token,
      },
    };
    axios(instance)
      .catch((e) => {
        if(e.response.status === 401){
          setModal({open:true, title: e.response.data.error, msg: 'Vuelva a autenticarse'});
        }
      })
  }; 
 
  const handleModalClose = () => {
    setModal(false);
    logout(); 
  };  
    
  if (!user) {
    return <Navigate to="/" />;
  } 

  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenAlert({open: false, msg: ""});
  };  
  
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <DialogGeneric 
        dataModal={modal}
        handleModalClose={handleModalClose}
       />
      <MainStyle>
        {outlet}
      </MainStyle>
      <Snackbar 
          open={openAlert.open} 
          onClose={handleSuccess}       
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert variant="filled" severity="error" onClose={handleSuccess}>
            <AlertTitle>{openAlert.title}</AlertTitle>
            {openAlert.msg}
          </Alert>
        </Snackbar> 
    </RootStyle>
  );
}
