
import { useState, useEffect, useCallback } from 'react';
import { Card, Stack, Container, Typography, Alert, Snackbar } from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import axios, { handleAxiosError } from '../config/axios';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import ConfirmProductsForm from "../sections/production/ConfirmProductsForm";
import RemitosColumns from '../data/table/columns/confirmremitos';

export default function ConfirmRemitos() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [remitosInternos, setRemitosInternos] = useState([]);
  const [selectedRemito, setSelectedRemito] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(null);  
  const { token } = JSON.parse(window.localStorage.getItem("user"));
  /* const [ listeningStream , setListeningStream ] = useState(false);

  useEffect( () => {
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      const events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.module === 'stock'){
          setDataUpdate(data);
        }
      };
      setListeningStream(true);
    }
  }, [listeningStream, token]); */


  const onFetchRemitosInternos = useCallback(() => {   
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: '/production/remito/list',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setRemitosInternos(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [token]);  
  
  useEffect(()=> {
    onFetchRemitosInternos();
  }, [onFetchRemitosInternos])

  
  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenSuccess({open: false, msg: ""});
  };  

  const success = () => {
    setOpenModalView(false);
    setSelectedRemito(null);
    setOpenSuccess({open: true, msg: "Se dieron de baja la/s unidad/es correctamente"});
  };

  const handleModalOpen = (remito) => {
    setSelectedRemito(remito);
    setOpenModalView(true);
  };  

  const handleModalClose = () => {
    setOpenModalView(false);
    setSelectedRemito(null)
  };  

  const initializationDataUpdate = () => {
    setDataUpdate(null);
}

  const actions = [
    {
        icon: <ArticleOutlinedIcon color='primary' fontSize='medium'/>,
        tooltip: "Ver remito",
        onClick: (event, rowData) => {
          handleModalOpen(rowData);
        },
    },
  ];

  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Dar Baja">
      <Container maxWidth="xl" disableGutters>      
        {!!openModalView && 
         <ConfirmProductsForm 
          openModal={openModalView}
          handleModalClose={handleModalClose}         
          remito={selectedRemito}
          success={success}
         />     
        }    
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom textAlign={{xs: "center"}}>
            Confirmar Remitos Internos
          </Typography>   
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
        <Card>                
          <Scrollbar>     
            <TableV2 
              columns={RemitosColumns(remitosInternos)} 
              data={remitosInternos}
              actions={actions}
              dataUpdate={dataUpdate}
              initializationDataUpdate={initializationDataUpdate}
              nameComponent="dar-baja"
            />      
          </Scrollbar>          
        </Card>      
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>        
    </Page>   
    </>
  );
}
