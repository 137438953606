import { Box, Paper, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DataTableEditTemporal from '../../components/DataTableEditTemporal';
import ProductsRemitoInternoEditColumns from '../../data/table/columns/productsremitointernoedit';
import ProductsRemitoInternoColumns from '../../data/table/columns/productsremitointerno';

import axios, { handleAxiosError } from "../../config/axios";

ConfirmProductsForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    success: PropTypes.func.isRequired,
    remito: PropTypes.object.isRequired,
};

export default function ConfirmProductsForm(props) {
    const {success, handleModalClose, openModal, remito} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [remitoInterno, setRemitoInterno] = useState([]);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const theme = useTheme();

    const { token } = JSON.parse(window.localStorage.getItem("user"));       
  
    useEffect(() => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/production/remito/${id}?confirmado=${!!remito.CONFIRMADO}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setRemitoInterno(data.data.data);      
                } else {
                    setError({open: true, title: 'No se pudo encontrar el remito interno', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el remito interno', msg: handleAxiosError(e)});
            })
        };

        onFetchView(remito.ID_REMITO_INTERNO);

    }, [token, remito.ID_REMITO_INTERNO, remito.CONFIRMADO]);

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const handleModalConfirmOpen = () => {
        setOpenModalConfirm(true);
      };  
    
    const handleModalConfirmClose = () => {
        setOpenModalConfirm(false)
      }; 

    const handleSelections = (productSelection) => {  
        remitoInterno.map(product => {
            if(product.id === productSelection.id){
                product.A_DESCONTAR = product.CANTIDAD_NOMINAL - productSelection.value;
            }         
        })      
        setRemitoInterno(remitoInterno);
    }

    const onSubmit = () => {
        handleModalConfirmClose();
        const discountProducts = remitoInterno.filter(product => product.A_DESCONTAR > 0).map(product => ({
            id_producto: product.ID_PRODUCTO,
            id_empleado: product.ID_EMPLEADO,
            id_remito_interno: product.ID_REMITO_INTERNO,
            cantidad: product.A_DESCONTAR,

        }));
        onFetchConfirmRemito(discountProducts);
        
    };

    const onFetchConfirmRemito = (discountProducts) => {   
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: `/production/remito/confirm/${remito.ID_REMITO_INTERNO}`,
            headers: {
                "x-auth-token": token,
            },
            data: { products: discountProducts },
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){                 
                handleModalClose();
                success();
            } else {
                setError({open: true, title: 'No se pudo confirmar remito interno', msg: data.data.error});
            }
        })
        .catch((e) => {           
            setError({open: true, title: 'No se pudo confirmar remito interno', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };
    
    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'lg'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Detalle remito interno N° {remito.NUMERO}  
                    </Typography>                       
                </Grid>
                <Grid item xs={12} sm={1} align="right">                    
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={2}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >                                   
                                <Grid item xs={12} sm={12} mt={0}>
                                    <DataTableEditTemporal
                                        columns={(remito.CONFIRMADO) ? ProductsRemitoInternoColumns() : ProductsRemitoInternoEditColumns()}
                                        data={remitoInterno}
                                        disableColumnMenu
                                        disableToolbar
                                        disableFooter
                                        styleHeader={{
                                            backgroundColor:theme.palette.primary.main, 
                                            color:"white",
                                        }}                
                                        height={250}    
                                        handleSelections={handleSelections} 
                                        density="compact"
                                        /* isCellEditable={(params) => params.row.CANTIDAD_REAL > 0} */
                                    />
                                </Grid>                                
                            </Grid>   
                        </Grid>                      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={0}>    
                <Grid item xs={12} sm={12} align="right">  
                    {!remito.CONFIRMADO && 
                    <>
                        <Button variant="contained" color="primary" disabled={isLoading} onClick={handleModalConfirmOpen}>
                            Confirmar
                        </Button>
                        <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{ marginLeft: 1 }}>
                            Cancelar
                        </Button>
                    </>
                    }
                    {!!remito.CONFIRMADO && 
                    <>
                        <Button variant="contained" color="primary" onClick={handleModalClose} sx={{ marginLeft: 1 }}>
                            Cerrar
                        </Button>
                    </>
                    }
                     
                </Grid>
            </Grid>         
        </DialogActions> 
        <Dialog
            open={openModalConfirm}
            onClose={handleModalConfirmClose}
            onBackdropClick={handleModalConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Dar de baja"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"¿Desea confirmar el remito interno?"}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    {"Su confirmación generará la baja de la/s unidad/es calculadas de la diferencia de la cantidad que figura en el remito interno y la cantidad real que introdujo del stock y su vinculación al empleado correspondiente, la cual no se podra deshacer!"}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    {"Considerar que esta opción impactara directamente en la producción de los empleados vinculados!."}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" disabled={isLoading} onClick={onSubmit}>
                    Aceptar
                </Button>
                <Button variant="outlet" color="secondary" onClick={handleModalConfirmClose}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>   
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>        
    </Dialog> 
    );
}
