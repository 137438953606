import { Box, Paper, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DataTableEditTemporal from '../../components/DataTableEditTemporal';
import PpTransferColumns from '../../data/table/columns/pptransfer';

import axios, { handleAxiosError } from "../../config/axios";

ToTransferPPForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    toTransferPP: PropTypes.object.isRequired,
    success: PropTypes.func.isRequired,
    selectionTransferToStock: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function ToTransferPPForm(props) {
    const {success, handleModalClose,openModal,toTransferPP,selectionTransferToStock} = props;
    const [product, setProduct] = useState([]);
    const [pps, setPps] = useState([]);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const theme = useTheme();

    const { token } = JSON.parse(window.localStorage.getItem("user"));       
  
    useEffect(() => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
            })
        };
        const onFetchViewPP = () => {
            const instance = { 
                method: 'get', 
                url: `/production/${toTransferPP.idPP}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    const dataPps = data.data.data;
                    if(dataPps.length < 1){
                        setError({open: true, title: 'No es posible marcar unidades para traspasar a stock', msg: 'No hay unidades en proceso'});
                    }
                    if(selectionTransferToStock.length > 0){
                        dataPps.map(pp => {
                            const selections = selectionTransferToStock.filter(selection => selection.id === pp.id)
                            pp.A_TRASPASAR = (selections.length > 0) ? selections[0].A_TRASPASAR : pp.A_TRASPASAR;
                        })
                    }
                    setPps(dataPps);  
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: handleAxiosError(e)});
            })
        };

        onFetchView(toTransferPP.idProduct);
        onFetchViewPP(toTransferPP.idPP);        

    }, [token, toTransferPP.idPP, toTransferPP.idProduct, selectionTransferToStock]);

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
        handleModalClose();
    };

    const handleSelections = (ppSelection) => {  
        pps.map(pp => {
            pp.A_TRASPASAR = (pp.id === ppSelection.id) ? ppSelection.value : pp.A_TRASPASAR;            
        })      
        setPps(pps);
    }

    const onSubmit = () => {
        success(pps);
    };

    
    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        A Traspasar  |  {product.NOMBRE} | {toTransferPP.dayPP} {toTransferPP.datePP}   
                    </Typography>                       
                </Grid>
                <Grid item xs={12} sm={1} align="right">                    
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={2}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >                                   
                                <Grid item xs={12} sm={12} mt={0}>
                                    <DataTableEditTemporal
                                        columns={PpTransferColumns()}
                                        data={pps}
                                        disableColumnMenu
                                        disableToolbar
                                        disableFooter
                                        styleHeader={{
                                            backgroundColor:theme.palette.primary.main, 
                                            color:"white",
                                        }}                
                                        height={250}    
                                        handleSelections={handleSelections} 
                                        density="compact"
                                    />
                                </Grid>                                
                            </Grid>   
                        </Grid>                      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={0}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        A traspasar
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>    
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
