/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';


function AutoComplete(props) {
    const { options, onChange, label, disabled } = props;
    const [value, setValue] = useState(null);

    return (
      <Autocomplete
        value={value}
        options={options}
        onChange={(event, newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        sx={{ marginTop: '8px' }}
        renderInput={(params) => <TextField {...params} label={label} disabled={disabled} />}
        disabled={disabled}
      />
    );
  }
export default AutoComplete;