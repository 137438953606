
export default (product) => ({
  rowsData: [
    { field: "Nombre", value: product.NOMBRE, default: " - "},
    { field: "Clase", value: product.PRODUCTO_CLASE_DESCRIPCION, default: " - "},
    { field: "Tipo", value: product.PRODUCTO_TIPO, default: " - "},
    { field: "Subtipo", value: product.PRODUCTO_SUBTIPO, default: " - "},
    { field: "Unidad", value: product.PRODUCTO_UNIDAD_MEDIDA, default: " - "},        
    { field: "Fecha Alta", value: product.FECHA_ALTA, default: " - "},
    { field: "Descripción", value: product.DESCRIPCION, default: " -"},
  ],  
  rowsStock: [
    { field: "Ubicación", value: "GENERAL", default: 0, variant: 'outlined'  },
    { field: "Total", value: product.STOCK_TOTAL, default: 0,},   
    { field: "Disponible", value: product.STOCK_DISPONIBLE, default: 0 },
    { field: "Reservado", value: product.STOCK_RESERVA, default: 0},   
    { field: "A producir", value: product.STOCK_A_PRODUCIR, default: 0},
    { field: "A producir real", value: product.STOCK_A_PRODUCIR_REAL, default: 0},
    { field: "Disponible real", value: product.STOCK_DISPONIBLE_REAL, default: 0},
    { field: "Minimo disp.", value: product.STOCK_MINIMO_DISPONIBLE, default: '-', variant: 'outlined'},
  ],  
  rowsStockDetailFabrica: [       
    { value: "FABRICA", default: 0, marginLeft: 2, variant: 'outlined' },
    { value: product.STOCK_TOTAL_FABRICA, default: 0, marginLeft: 2, }, 
    { value: product.STOCK_DISPONIBLE_FABRICA, default: 0, marginLeft: 2, },
    { value: product.STOCK_RESERVA_FABRICA, default: 0, marginLeft: 2, },
    { value: product.STOCK_A_PRODUCIR_FABRICA, default: 0, marginLeft: 2, },
  ],  
  rowsStockDetailDomenech: [
    { value: "DOMENECH", default: 0, marginLeft: 2, variant: 'outlined' },
    { value: product.STOCK_TOTAL_DOMENECH, default: 0, marginLeft: 2, },        
    { value: product.STOCK_DISPONIBLE_DOMENECH, default: 0, marginLeft: 2, },
    { value: product.STOCK_RESERVA_DOMENECH, default: 0, marginLeft: 2, },
    { value: product.STOCK_A_PRODUCIR_DOMENECH, default: 0, marginLeft: 2, },
  ],
  rowsStockDetailC3: [
    { value: "C3", default: 0, marginLeft: 2, variant: 'outlined' },
    { value: product.STOCK_TOTAL_C3, default: 0, marginLeft: 2, },        
    { value: product.STOCK_DISPONIBLE_C3, default: 0, marginLeft: 2, },
    { value: product.STOCK_RESERVA_C3, default: 0, marginLeft: 2, },
    { value: product.STOCK_A_PRODUCIR_C3, default: 0, marginLeft: 2, },
  ],
})

