
export default (product, isAlambre, isPostePlaca, handleMinimumAvailable) => ({
    rowsData : [
      { field: "Código", value: product.ID_PRODUCTO},
      { field: "Clase", value: product.PRODUCTO_CLASE_DESCRIPCION},
      { field: "Tipo", value: product.PRODUCTO_TIPO},
      { field: "Subtipo", value: product.PRODUCTO_SUBTIPO},
      { field: "Unidad", value: product.PRODUCTO_UNIDAD_MEDIDA},        
      { field: "Fecha Alta", value: product.FECHA_ALTA},
      { field: "Descripción", value: product.DESCRIPCION, default: " -"},
    ],
    rowsAmount : [
      { field: "Precio venta", value: product.PRECIO_VENTA, default: " -", startAdornment: "$"},
      { field: "Costo producción", value: product.COSTO_PRODUCCION, default: " -", startAdornment: "$"},
      { field: "Costo compra", value: product.COSTO_COMPRA, default: " -", startAdornment: "$"},
    ],
    rowsAlambre : [
        { field: "Rombo", value: product.ROMBO, default: " -", hidden: !isAlambre},
        { field: "Calibre", value: product.CALIBRE, default: " -", hidden: !isAlambre},
        { field: "Alura", value: product.ALTURA, default: " -", hidden: !isAlambre},
        { field: "Kg por rollo", value: product.KG_X_ROLLO, default: " -", endAdornment: "kg", hidden: !isAlambre},
    ],
    rowsPostePlaca : [
        { field: "Kg por unidad", value: product.KG_X_UNIDAD, default: " -", endAdornment: "kg", hidden: !isPostePlaca},
    ],
    rowsStock : [
        { field: "Ubicación", value: "GENERAL", default: 0, variant: 'outlined'  },
        { field: "Total", value: product.STOCK_TOTAL, default: 0,},   
        { field: "Disponible", value: product.STOCK_DISPONIBLE, default: 0 },
        { field: "Reservado", value: product.STOCK_RESERVA, default: 0},
        { field: "A producir", value: product.STOCK_A_PRODUCIR, default: 0},
        { field: "A producir real", value: product.STOCK_A_PRODUCIR_REAL, default: 0},
        { field: "Disponible real", value: product.STOCK_DISPONIBLE_REAL, default: 0},
        { field: "Minimo disponible", value: product.STOCK_MINIMO_DISPONIBLE, default: '-', variant: 'outlined', action: true, onclick: handleMinimumAvailable},
    ],
    rowsStockDetailFabrica : [       
        { value: "FABRICA", default: 0, marginLeft: 2, variant: 'outlined' },
        { value: product.STOCK_TOTAL_FABRICA, default: 0, marginLeft: 2, }, 
        { value: product.STOCK_DISPONIBLE_FABRICA, default: 0, marginLeft: 2, },
        { value: product.STOCK_RESERVA_FABRICA, default: 0, marginLeft: 2, },
        { value: product.STOCK_A_PRODUCIR_FABRICA, default: 0, marginLeft: 2, },
    ],
    rowsStockDetailDomenech : [
        { value: "DOMENECH", default: 0, marginLeft: 2, variant: 'outlined' },
        { value: product.STOCK_TOTAL_DOMENECH, default: 0, marginLeft: 2, },        
        { value: product.STOCK_DISPONIBLE_DOMENECH, default: 0, marginLeft: 2, },
        { value: product.STOCK_RESERVA_DOMENECH, default: 0, marginLeft: 2, },
        { value: product.STOCK_A_PRODUCIR_DOMENECH, default: 0, marginLeft: 2, },
    ],
    rowsStockDetailC3 : [
        { value: "C3", default: 0, marginLeft: 2, variant: 'outlined' },
        { value: product.STOCK_TOTAL_C3, default: 0, marginLeft: 2, },        
        { value: product.STOCK_DISPONIBLE_C3, default: 0, marginLeft: 2, },
        { value: product.STOCK_RESERVA_C3, default: 0, marginLeft: 2, },
        { value: product.STOCK_A_PRODUCIR_C3, default: 0, marginLeft: 2, },
    ],
})