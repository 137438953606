/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip, Zoom, Chip, Typography, Stack } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import { useLocalStorage } from "../hooks/useLocalStorage";

TableV2.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    showColumnFilters: PropTypes.bool,
    enableGrouping: PropTypes.bool,
    enableExports: PropTypes.bool,
    enableEditing: PropTypes.bool,
    enableTitle: PropTypes.bool,
    dataUpdate: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object, PropTypes.bool, PropTypes.number]),
    initializationDataUpdate: PropTypes.func,
    dataUpdateFields: PropTypes.arrayOf(PropTypes.string),
    urlExportPdf: PropTypes.string,
    nameComponent: PropTypes.string,
    title: PropTypes.string,
};

TableV2.defaultProps = {
    dataUpdateFields: [],
};

export default function TableV2(props){
    const [pagination, setPagination] = useLocalStorage(`${props.nameComponent}-pagination`, { pageIndex: 0, pageSize: 50 });
    const [tableData, setTableData] = useState([]);

    useEffect(()=> {
        setTableData(props.data);
      }, [props.data])

    useEffect(()=> {
        const handleUpdateData = async (dataUpdate) => {
            if(typeof dataUpdate === 'number'){
                // console.log("NUMBER")
                const index = tableData.findIndex( element => element.ID_PRODUCTO === dataUpdate );
                if(index >= 0){
                    tableData.splice( index, 1 );            
                }
            } else if (Array.isArray(dataUpdate)){
                // console.log("ARRAY")
                // eslint-disable-next-line no-restricted-syntax
                for (const data of dataUpdate) {
                    const index = tableData.findIndex( (element) => element.ID_PRODUCTO === data.ID_PRODUCTO);
                    if (index < 0) {
                        tableData.push(data);
                    } else {
                        tableData[index] = data;
                    }  
                }
            } else {
                // console.log("OBJECT")
                const index = tableData.findIndex( (element) => element.ID_PRODUCTO === dataUpdate.ID_PRODUCTO);
                if (index < 0) {
                    tableData.push(dataUpdate);
                } else if(props.dataUpdateFields.length > 0){
                        // console.log("FIELDS")
                        // eslint-disable-next-line no-restricted-syntax
                        for (const field of props.dataUpdateFields) {
                            tableData[index][field] = dataUpdate[field];
                        }
                } else {
                    // console.log("NOT FIELDS")
                    tableData[index] = dataUpdate;
                }         
            }
            setTableData([...tableData]);   
            props.initializationDataUpdate();
          };
          
        if(props.dataUpdate){
            handleUpdateData(props.dataUpdate);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.dataUpdate])

    const columns = useMemo(
        () => props.columns,
        [props.columns],
    );

    const onFetchExportPDF = (idProducts = null) => {   
        const { token } = JSON.parse(window.localStorage.getItem("user"));        
        const link = document.createElement('a');
        let href = `${process.env.REACT_APP_API_URL}/${props.urlExportPdf}?xauthtoken=${token}`;
        if(idProducts){
            href += `&id_products=[${idProducts}]`;
        }
        link.href = href;
        link.target = "_blank"
        link.rel = "noreferrer"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);          
    };

    const handleExportRows = (rows) => {
        const idProducts = rows.map((row) => row.original.ID_PRODUCTO);
        onFetchExportPDF(idProducts);
      };
    
    const handleExportData = () => {
        onFetchExportPDF();
    };

    const onPageChange = (event, page) => {
        setPagination({pageIndex:page, pageSize:pagination.pageSize})
    }

    const onRowsPerPageChange = (event) => {        
        setPagination({pageIndex: pagination.pageIndex, pageSize:event.target.value})
    }

    const handleSaveCell = (cell, column, row, value) => {
        tableData[cell.row.index][cell.column.id] = value;
        setTableData([...tableData]);
    };

    return <MaterialReactTable 
        columns={columns} 
        data={tableData} 
        renderTopToolbarCustomActions={({ table }) => (
           /*  <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}> */
           <Stack direction="row">
                {props.enableExports && <>
                    <Button
                        color="primary"
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        sx={{paddingLeft: { xs: "24px" , sm: "16px"}}}
                    >
                        <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                            Exportar{'\u00A0'}
                        </Typography>     
                        Todo                   
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        sx={{marginLeft:1, paddingLeft: { xs: "24px" , sm: "16px"}}}
                    >
                        <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                            Exportar{'\u00A0'} 
                        </Typography>  
                        Actual
                    </Button>
                </>}
                {props.enableTitle && <>
                    <Chip label={props.title} />
                </>}
            </Stack>
            /* </Box> */
        )}
        renderRowActions={({row}) => (
            <Box>
                {props.actions
                    .filter(action => !action.hidden)
                    .map((action, index) =>  (
                        <Tooltip 
                            key={index}
                            title={action.tooltip} 
                            TransitionComponent={Zoom} 
                            placement="top" 
                            describeChild 
                            enterDelay={1500} 
                            leaveDelay={100} 
                        >
                            <IconButton onClick={(event) => action.onClick(event, row.original)}>
                                {action.icon}
                            </IconButton>
                        </Tooltip>
                    )
                )}
            </Box>)
        }
        enableEditing={props.enableEditing}
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell, column, row }) => ({
            onBlur: (event) => {
              handleSaveCell(cell, column, row, event.target.value);
            },
        })}
        enableGrouping={props.enableGrouping}
        enableClickToCopy
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableStickyHeader
        enableStickyFooter
        enablePinning={false}
        enableRowActions={props.actions !== undefined}
        positionActionsColumn="last"
        enableDensityToggle={false} // density does not work with memoized rows
        enableHiding={false} // column hiding does not work with memoized rows
        memoMode="rows" // memoize table rows to improve render performance, but break a lot of features
        initialState={{
            density: 'compact',
            pagination,
            showColumnFilters: props.showColumnFilters, 
            showGlobalFilter: true,
            expanded: true,
        }}
        state={{
            isLoading: props.isLoading,
            pagination
        }}
        muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 50, 100, 200, 300],
            onPageChange,
            onRowsPerPageChange
        }}
        muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        localization={MRT_Localization_ES}        
    />;
};