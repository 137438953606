export default () => [
   { 
    field: 'CANTIDAD', 
    headerName: 'Cantidad', 
    width: 108,
    type: 'number',
    description: 'Cantidad de producto en proceso',
  },
  { 
    field: 'NOMBRE_COMPLETO', 
    headerName: 'Empleado', 
    width: 690,
    description: 'Empleado productor',
  },   
];
