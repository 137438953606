/* eslint-disable react/prop-types */
import { Typography, Zoom, Tooltip, Chip } from '@mui/material';

const tooltipCompleteText = (cell) => (
  <Tooltip 
    title={cell.getValue()} 
    TransitionComponent={Zoom} 
    placement="top" 
    arrow 
    describeChild 
    enterDelay={2500} 
    leaveDelay={100} 
  >
    <Typography 
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}
    >
      {cell.getValue()}
    </Typography>
  </Tooltip>    
);

const tooltipDistributionStock = (cell, row, state, color = null) => (
  <Tooltip 
    title={`
      Fabrica:${row.original[`STOCK_${state}_FABRICA`]} | 
      Domenech:${row.original[`STOCK_${state}_DOMENECH`]} |
      C3:${row.original[`STOCK_${state}_C3`]}
    `} 
    TransitionComponent={Zoom} 
    placement="top" 
    arrow 
    describeChild 
    enterDelay={1000} 
    leaveDelay={100} 
  >
    <Typography align="left" color={color}>{cell.getValue()}</Typography>
  </Tooltip>    
);

const text = (cell, color = null) => <Typography align="left" color={color}>{cell.getValue()}</Typography>;

const status = (cell) => {
  const status = !!cell.getValue();
  const color = (status) ? 'success' : 'error';
  const label = (status) ? 'ENVIADA' : 'NO ENVIADA';
  return <Chip color={color} label={label} variant="outlined"/> 
};

export default () => [
  { 
    accessorKey: 'ID_PRODUCTO', 
    header: 'Id', 
    size: 1,
    enableClickToCopy: false,
    enableColumnDragging: false,
    enableGrouping: false,
    enableColumnFilter: false,
    muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
    Cell: ({ cell }) => text(cell),
  },
  { 
    accessorKey: 'NOMBRE', 
    header: 'Nombre', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) => tooltipCompleteText(cell),
  },
  { 
    accessorKey: 'STOCK_EN_PROCESO', 
    header: 'En Proceso', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    Cell: ({ cell }) => text(cell), 
  },
  { 
    accessorKey: 'STOCK_MINIMO_DISPONIBLE', 
    header: 'Minimo', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    Cell: ({ cell }) => text(cell,'#FF6347'),
    Header: ({ column }) => (<div style={{ color: '#FF6347' }}>{column.columnDef.header}</div>),
  },
  { 
    accessorKey: 'STOCK_DISPONIBLE', 
    header: 'Disponible', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    Cell: ({ cell, row }) => tooltipDistributionStock(cell, row, 'DISPONIBLE'),
  },
  { 
    accessorKey: 'STOCK_RESERVA', 
    header: 'Reserva', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    Cell: ({ cell, row }) => tooltipDistributionStock(cell, row, 'RESERVA'),
  },
  { 
    accessorKey: 'STOCK_A_PRODUCIR_REAL', 
    header: 'Producir', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,     
    Cell: ({ cell }) => text(cell),     
  },
  { 
    accessorKey: 'STOCK_TOTAL', 
    header: 'Total', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    Cell: ({ cell, row }) => tooltipDistributionStock(cell, row, 'TOTAL'),  
  },   
  { 
    accessorKey: 'ENVIO_ALERTA_DIARIA', 
    header: 'Alerta', 
    size: 10,
    enableClickToCopy: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  status(cell), 
  },   
];
