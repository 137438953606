import PropTypes from 'prop-types';
import { Paper, Dialog, DialogContent } from '@mui/material';
import WeekPicker from "../../components/WeekPicker";

WeekPickerModal.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    setDate: PropTypes.func.isRequired,
    date: PropTypes.object.isRequired,
    daysToMark: PropTypes.bool,
    urlDaysToMark: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default function WeekPickerModal(props) {
    const {handleModalClose, openModal, setDate, date, daysToMark, urlDaysToMark, token, type} = props;

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} maxWidth={'sm'} onBackdropClick={handleModalClose}>
            <DialogContent sx={{margin: 0, padding: 0}}>    
                <Paper>            
                    <WeekPicker 
                        setDate={setDate} 
                        date={date} 
                        daysToMark={daysToMark} 
                        urlDaysToMark={urlDaysToMark} 
                        token={token}
                        type={type}
                    />   
                </Paper>            
            </DialogContent>     
         </Dialog> 
    );
}
