/* eslint-disable camelcase */
import { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton } from '@mui/material';
import { useAuth } from "../../hooks/useAuth";
  
import MenuPopover from '../../components/MenuPopover';
  
import account from '../../_mock/account';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();

  const [open, setOpen] = useState(null);

  const { username, nombre_completo, email } = JSON.parse(window.localStorage.getItem("user"));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.primary' }} noWrap>
            {nombre_completo}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        
        {!!user && (
            <MenuItem key={"logout"} onClick={logout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
