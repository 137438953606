import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
export default axiosInstance;

export const handleAxiosError = (error) => {
  let mge = 'Verifique su conexión';
  if (error.response) {
    if (!error.response.data.ok && error.response.data.data === null) {
      mge = error.response.data.error;
    }
  } else if (error.request) {
    mge = 'Tiempo de espera excedido';
  }
  return mge;
};
