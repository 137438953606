import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
  
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';  
  
import useResponsive from '../../hooks/useResponsive';
  
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';

import navConfig from './NavConfig';

const DRAWER_WIDTH = 160;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const [previousPathname, setPreviousPathname] = useState('');
  const currentPathname = new URL(window.location.href).pathname;
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    setPreviousPathname(currentPathname);
  }, [currentPathname]);

  useEffect(() => {
    if (isOpenSidebar && previousPathname !== currentPathname) {
      onCloseSidebar();
    }
  }, [isOpenSidebar, onCloseSidebar, previousPathname, currentPathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 1.5, py: 1, display: 'inline-flex' }}>
        <Logo sx={{ width: 246, height: 65}}/>
      </Box>
      <NavSection navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
