
import { useState, useEffect, useCallback, Profiler } from 'react';
import { Grid, Button, Card, Stack, Container, Typography, Alert, Snackbar } from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EditIcon from '@mui/icons-material/Edit';
import axios, { handleAxiosError } from '../config/axios';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import AddProductForm from "../sections/stock/AddProductForm";
import EditProductForm from "../sections/stock/EditProductForm";
import ViewProductModal from "../sections/stock/ViewProductModal";
import RegisterMovementForm from "../sections/stock/RegisterMovementForm";
import StockColumns from '../data/table/columns/stockV2';

export default function Stock() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModaledit, setOpenModalEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [products, setProducts] = useState([]);
  const [idNewProduct, setIdNewProduct] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [types, setTypes] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [classes, setClasses] = useState([]);
  const [unitsMeasure, setUnitsMeasure] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [positions, setPositions] = useState([]);
  const [typesMovements, setTypesMovements] = useState([]);
  const [typesSubMovements, setTypesSubMovements] = useState([]);  
  const [dataUpdate, setDataUpdate] = useState(null);  
  const { rolAdmin, rolUpdateStock, token } = JSON.parse(window.localStorage.getItem("user"));
  const [ listeningStream , setListeningStream ] = useState(false);

  useEffect( () => {
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      const events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.module === 'stock'){
          setDataUpdate(data);
        }
      };
      setListeningStream(true);
    }
  }, [listeningStream, token]);


  const onFetchProducts = useCallback(() => {   
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: '/product',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [token]);  

  const onFetchCategorys = useCallback(() => {    
    const instance = { 
      method: 'get', 
      url: '/categories',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){
          const _typesMovements = addDefaultTypes(data.data.data.movimiento_tipos, 0, 'Selecciona un tipo');
          const _subtypesMovements = addDefaultTypes(data.data.data.movimiento_subtipos, 0, 'Selecciona un subtipo');
          const _types = addDefaultTypes(data.data.data.tipos, 0, 'Selecciona un tipo');
          const _subtypes = addDefaultTypes(data.data.data.subtipos, 0, 'Selecciona un subtipo');
          
          setIdNewProduct(data.data.data.id_new);
          setTypes(_types);
          setSubtypes(_subtypes);
          setClasses(data.data.data.clases);
          setUnitsMeasure(data.data.data.unidades_medida);
          setDeposits(data.data.data.depositos);
          setPositions(data.data.data.posiciones);
          setTypesMovements(_typesMovements);
          setTypesSubMovements(_subtypesMovements);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
      })
      .catch((e) => {
        setError(handleAxiosError(e));
      })
  }, [token]);
  
  useEffect(()=> {
    onFetchProducts();
    onFetchCategorys();
  }, [onFetchCategorys, onFetchProducts])

  

  const addDefaultTypes = (data, value, label) => {
    data.push({value, label});
    return data;
  };
  
  const refreshAddProduct = () => {
    onFetchProducts();
    onFetchCategorys();
    setOpenSuccess({open: true, msg: "Se agrego el producto correctamente"});
    setOpenModalAdd(false);
    setSelectedProductId(null);
  };
  
  const refreshEditProduct = () => {
    onFetchProducts();
    onFetchCategorys();
    setOpenSuccess({open: true, msg: "Se edito el producto correctamente"});
    setOpenModalEdit(false);
    setSelectedProductId(null);
  };

  const handleModalAddOpen = () => {
    setOpenModalAdd(true);
  };

  const handleModalAddClose = () => {
    setOpenModalAdd(false);
  };

  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenSuccess({open: false, msg: ""});
  };  


  const refreshDeleteProduct = () => {
    onFetchProducts();
    onFetchCategorys();
    setOpenSuccess({open: true, msg: "Se elimino el producto correctamente"});
    setOpenModalView(false);
    setSelectedProductId(null);
  };

  const handleModalViewOpen = (idProduct) => {
    setSelectedProductId(idProduct);
    setOpenModalView(true);
  };  

  const handleModalViewClose = () => {
    setOpenModalView(false);
    setSelectedProductId(null)
  };  
    
  const handleModalRegisterOpen = (idProduct = null, selected = false) => {
    if(selected){
      setSelectedProductId(idProduct);
    }
    setOpenModalRegister(true);
  };
    
  const handleModalEditOpen = (idProduct = null, selected = false) => {
    if(selected){
      setSelectedProductId(idProduct);
    }
    setOpenModalEdit(true);
  };

  const handleModalEditClose = () => {
    setOpenModalEdit(false);
    setSelectedProductId(null);
  };

  const handleModalRegisterClose = () => {
    setOpenModalRegister(false);
    setSelectedProductId(null);
  };

  const successRegister = () => {
    setOpenSuccess({open: true, msg: "Se registro el movimiento correctamente"});
    setOpenModalRegister(false);
    setSelectedProductId(null);
  };

  const initializationDataUpdate = () => {
    setDataUpdate(null);
}

  const actions = [
    {
        icon: <ArticleOutlinedIcon color='primary' fontSize='medium'/>,
        tooltip: "Ver detalles",
        onClick: (event, rowData) => {
          handleModalViewOpen(rowData.ID_PRODUCTO);
        },
    },
    {
      icon: <AppRegistrationIcon color='primary' fontSize='medium'/>,
      tooltip: "Registrar movimiento",
      onClick: (event, rowData) => {
        handleModalRegisterOpen(rowData.ID_PRODUCTO, true);
      },
      hidden: !rolUpdateStock,
    },
    {
        icon: <EditIcon color='primary' fontSize='medium'/>,
        tooltip: "Editar producto",
        onClick: (event, rowData) => {
          handleModalEditOpen(rowData.ID_PRODUCTO, true);
        },
        hidden: !rolAdmin,
    },
  ];

  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Stock">
      <Container maxWidth="xl" disableGutters>      
        {openModalView && 
         <ViewProductModal 
          handleModalClose={handleModalViewClose} 
          openModal={openModalView}
          idProduct={selectedProductId}
          refresh={refreshDeleteProduct}
          hiddenDelete={!rolUpdateStock}
         />     
        }
        {openModalAdd && 
         <AddProductForm 
          handleModalClose={handleModalAddClose} 
          openModal={openModalAdd}
          idProduct={idNewProduct}
          refresh={refreshAddProduct}
          types={types} 
          subtypes={subtypes}
          classes={classes}
          unitsMeasure={unitsMeasure}
         />     
        }
        {openModalRegister && 
         <RegisterMovementForm 
          handleModalClose={handleModalRegisterClose} 
          openModal={openModalRegister}
          idProduct={selectedProductId}
          maxLengthIdProduct={idNewProduct.toString().length}
          success={successRegister}
          deposits={deposits}
          positions={positions}
          typesMovements={typesMovements}
          typesSubMovements={typesSubMovements}
         />   
        }
        {openModaledit && 
         <EditProductForm 
          handleModalClose={handleModalEditClose} 
          openModal={openModaledit}
          idProduct={selectedProductId}
          maxLengthIdProduct={idNewProduct.toString().length}
          refresh={refreshEditProduct}
          types={types} 
          subtypes={subtypes}
          classes={classes}
          unitsMeasure={unitsMeasure}
         />   
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom pb={{ xs: 1, sm: 0 }}>
            Stock
          </Typography>     
          {rolUpdateStock && 
            <Grid>
              <Button variant="contained" onClick={handleModalRegisterOpen} sx={{paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<Iconify icon="ic:sharp-app-registration"/>}>
                <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                  Registrar Movimiento
                </Typography>
              </Button>
              <Button variant="contained" onClick={handleModalAddOpen} sx={{marginLeft:1, paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<Iconify icon="eva:plus-fill" />}>
                <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                  Crear Producto
                </Typography>
              </Button>    
              {rolAdmin && 
                <Button variant="contained" onClick={handleModalEditOpen} sx={{marginLeft:1, paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<Iconify icon="material-symbols:edit" />}>
                  <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                    Editar Producto
                  </Typography>
                </Button> 
              }   
            </Grid>
          }     
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
        <Card>                
          <Scrollbar>     
            <TableV2 
              columns={StockColumns(products)} 
              data={products}
              actions={actions}
              dataUpdate={dataUpdate}
              initializationDataUpdate={initializationDataUpdate}
              showColumnFilters
              enableGrouping
              enableExports
              urlExportPdf='product/download/pdf'
              nameComponent="stock"
            />      
          </Scrollbar>          
        </Card>      
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>        
    </Page>   
    </>
  );
}
