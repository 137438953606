/* eslint-disable react/prop-types */
import { Typography, Chip } from '@mui/material';

const status = (cell) => {
  const status = !!cell.getValue();
  const color = (status) ? 'success' : 'error';
  const label = (status) ? 'ACTIVO' : 'INACTIVO';
  return <Chip color={color} label={label} variant="outlined"/> 
};

const text = (cell, color = null) => <Typography align="left" color={color}>{cell.getValue()}</Typography>;

export default () => [
  { 
    accessorKey: 'ID_EMPLEADO', 
    header: 'Id', 
    size: 1,
    enableClickToCopy: false,
    enableColumnDragging: false,
    enableGrouping: false,
    enableColumnFilter: false,
    muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
    Cell: ({ cell }) => text(cell),
  },
  { 
    accessorKey: 'NOMBRE', 
    header: 'Nombre', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },
  { 
    accessorKey: 'APELLIDO', 
    header: 'Apellido', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },
  { 
    accessorKey: 'DNI', 
    header: 'DNI', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },
  { 
    accessorKey: 'FECHA_NACIMIENTO', 
    header: 'Fecha Nac.', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },
  { 
    accessorKey: 'DIRECCION', 
    header: 'Dirección', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },
  { 
    accessorKey: 'TELEFONO', 
    header: 'Telefono', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  text(cell),
  },  
  { 
    accessorKey: 'ACTIVO', 
    header: 'Estado', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableGrouping: false,
    enableClickToCopy: true,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  status(cell),
  },  
];
