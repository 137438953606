/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Grid, TextField, MenuItem, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

export default function EditableInput(props) {
    const { label, value, name, onSave, fullWidth, margin, select, options, InputProps, defaultValue, multiline, disabled, onChangeSelect } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [inputName, setInputName] = useState(value);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        onSave(inputValue,inputName);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setInputValue(value);
    };

    const handleInputChange = (event) => {
        if(onChangeSelect){
            onChangeSelect(event.target.value);
        }
        setInputValue(event.target.value);
        setInputName(event.target.name);
    };

    const defaultValueSelect = () => {        
        if(select){
            const selectedOption  = options.find(option => option.value === (inputValue??defaultValue));
            const label = selectedOption ? selectedOption.label : undefined;
            return label || options[0].label;        
        } 
        return inputValue ?? value;  
    }
    
    return (
    <>
        <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary' }}>
            {label}
        </Typography>
        {isEditing ? (
            <>
            <Grid container>
                <Grid item>
                    { (select) 
                    ? 
                    (<TextField
                        name={name}
                        value={inputValue??value}
                        onChange={handleInputChange}
                        fullWidth={fullWidth}
                        margin={margin}
                        InputProps={InputProps}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        variant="outlined"
                        select
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>)                       
                    : 
                    (<TextField
                        name={name}
                        value={inputValue??value}
                        onChange={handleInputChange}
                        fullWidth={fullWidth}
                        margin={margin}
                        InputProps={InputProps}
                        disabled={disabled}
                        multiline={multiline}
                        variant="outlined"
                    />)
                    }
                </Grid>    
                <Grid item mt={2}>
                    <IconButton onClick={handleSaveClick} disabled={disabled}>
                        <SaveIcon color={disabled ? 'disabled': 'primary'} fontSize='small'/>
                    </IconButton>
                    <IconButton onClick={handleCancelClick} disabled={disabled}>
                        <ClearIcon color={disabled ? 'disabled': 'primary'} fontSize='small'/>
                    </IconButton>
                </Grid>    
            </Grid>
        </>
        ) : (       
            ((defaultValue || value) && <>
                <Grid container>
                    <Grid item mt={1} p={2}>
                        <Typography variant="subtitle1">
                            {defaultValueSelect()}
                        </Typography>
                    </Grid>    
                    <Grid item mt={2}>
                        <IconButton onClick={handleEditClick} disabled={disabled}>
                            <EditIcon color={(disabled) ? 'disabled': 'primary'} fontSize='small'/>
                        </IconButton>
                    </Grid>    
                </Grid>
            </>)
        )}
    </>
    ); 
}