import { Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import PropTypes from 'prop-types';

DialogGeneric.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    dataModal: PropTypes.object.isRequired,
};

export default function DialogGeneric({dataModal, handleModalClose}) {
    return (
        <Dialog
            open={dataModal.open} 
            onClose={()=>handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dataModal.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dataModal.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleModalClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
