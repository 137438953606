
import { useState, useEffect, useCallback } from 'react';
import { Button, Card, Stack, Container, Typography, Alert, Snackbar, Grid, Switch } from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import useResponsive from "../hooks/useResponsive";
import WeekPickerModal from "../sections/production/WeekPickerModal";
import ConcreteMachining from "../sections/production/ConcreteMachining";
import AddPPForm from "../sections/production/AddPPForm";
import RemovePP from "../sections/production/RemovePP";
import UpgradePPPostePlacaForm from "../sections/production/UpgradePPPostePlacaForm";
import ToTransferPPForm from "../sections/production/ToTransferPPForm";
import TransferStockForm from "../sections/production/TransferStockForm";
import Iconify from '../components/Iconify';
import axios, { handleAxiosError } from '../config/axios';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import ProductColumns from '../data/table/columns/productsprocess';

export default function PPPostePlaca() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [products, setProducts] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(null);  
  const [date, setDate] = useState(dayjs());  
  const [openModalWeekPicker, setOpenModalWeekPicker] = useState(false);  
  const [openModalConcreteMachining, setOpenModalConcreteMachining] = useState(false);  
  const [chargedConcreteMachining, setChargedConcreteMachining] = useState(false);  
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalTransferStock, setOpenModalTransferStock] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removedProductId, setRemovedProductId] = useState(null);
  const [upgradePP, setUpgradePP] = useState(null);
  const [transferMode, setTransferMode] = useState(false);
  const [toTransferPP, setToTransferPP] = useState(null);
  const [transferToStock, setTransferToStock] = useState([]);
  const [ listeningStream , setListeningStream ] = useState(false);
  const [ dataUpdateFields , setDataUpdateFields ] = useState([]);
  const { rolUpdateStock, token } = JSON.parse(window.localStorage.getItem("user"));
  
  const theme = useTheme();
  const type = 'poste_placa';
  const isDesktop = useResponsive('up', 'lg');

  useEffect( () => {
    let events;
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.module === 'pp-posteplaca-total'){
          setDataUpdateFields(['STOCK_TOTAL', 'STOCK_TOTAL_FABRICA', 'STOCK_TOTAL_DOMENECH']);
          setDataUpdate(data);
        }
        if(data.module === 'pp-posteplaca'){
          const start = date.startOf('week');
          const end = date.endOf('week');
          const dayIsBetween = dayjs(data.date).isBetween(start, end, null, '[]');        
          if(dayIsBetween){
            setDataUpdate(data.list);
          }
        }
        if(data.module === 'pp-posteplaca-add'){
            setDataUpdate(data);
        }
        if(data.module === 'pp-posteplaca-remove'){
          setDataUpdate(data.value);
        }
      };
      setListeningStream(true);
    }
    return () => {
      if(events)events.close();
    };
  }, [date, listeningStream, token]);

  useEffect(()=>{
    setListeningStream(false);
  },[date]);


  const onFetchProductsProcess = useCallback(() => {   
    handleModalWeekPickerClose();
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: `/production?date=${date.format('YYYY-MM-DD')}&type=${type}`,
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [date, token]);  

  const onFetchVerifyChargeConcreteMachining = useCallback(() => {   
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: `/production/concrete_machining`,
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setChargedConcreteMachining(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [token]);  
 
  useEffect(()=> {
    onFetchProductsProcess();
    onFetchVerifyChargeConcreteMachining();
  }, [onFetchProductsProcess, onFetchVerifyChargeConcreteMachining])

  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenSuccess({open: false, msg: ""});
  };  

  const initializationDataUpdate = () => {
    setDataUpdate(null);
    setDataUpdateFields([]);
  }

  const handleModalWeekPickerClose = () => {
    setOpenModalWeekPicker(false);
  }; 

  const handleModalWeekPickerOpen = () => {
    setOpenModalWeekPicker(true);
  };

  const handleModalConcreteMachiningOpen = () => {
    setOpenModalConcreteMachining(true);
  };

  const handleModalConcreteMachiningClose = () => {
    setOpenModalConcreteMachining(false);
  };

  const handleModalTransferStockOpen = () => {
    setOpenModalTransferStock(true);
  };

  const handleModalAddOpen = () => {
    setOpenModalAdd(true);
  };

  const handleModalAddClose = () => {
    setOpenModalAdd(false);
  };

  const successAdd = () => {
    setOpenSuccess({open: true, msg: "Se añadio el producto correctamente"});
    setOpenModalAdd(false);
  };

  const successConcreteMachining = () => {
    setOpenSuccess({open: true, msg: "Se cargo la/s maquinada/s de hormigon correctamente"});
    setOpenModalConcreteMachining(false);
    setChargedConcreteMachining(true);
  };

  const successRemove = () => {
    setOpenSuccess({open: true, msg: "Se removio el producto correctamente"});
    setOpenModalRemove(false);
  };

  const successUpgradePP = () => {
    setOpenSuccess({open: true, msg: "Se actualizo el producto en proceso correctamente"});
    setUpgradePP(null);
  };

  const successTransferStock = () => {
    setToTransferPP(null)
    setTransferToStock([])
    setOpenModalTransferStock(false);
    setOpenSuccess({open: true, msg: "Se traspaso los productos en proceso al stock correctamente"});
  };

  const successToTransferPP = (selections) => {
    let list = selections;
    transferToStock.forEach(tts => {
      const equals = list.find(selection => selection.INDEX === tts.INDEX);
      if(!equals){
          list = [...list, tts]
      }
    });
    const listFilter = list.filter(pp => pp.A_TRASPASAR > 0);
    const listNotRepeats = Array.from( new Set( listFilter.map( JSON.stringify ) ) ).map( JSON.parse );
    setTransferToStock(listNotRepeats);
    setToTransferPP(null);
    setOpenSuccess({open: true, msg: "Se marco las unidades a traspasar al stock correctamente"});
  };

  const handleModalRemove = (idProduct) => {
    setRemovedProductId(idProduct);
    setOpenModalRemove(true);
  };  

  const handleModalRemoveClose = () => {
    setOpenModalRemove(false);
  };

  const handleUpgradePP = (cell) => {
    const idProduct = cell.row.original.ID_PRODUCTO;
    const datePP = cell.column.columnDef.meta;
    const dayPP = cell.column.columnDef.accessorKey;
    const idPP = `${idProduct}_${datePP}`;
    setUpgradePP({ idProduct, datePP, dayPP, idPP });
  };

  const handleTransferPP = (cell) => {
    const idProduct = cell.row.original.ID_PRODUCTO;
    const datePP = cell.column.columnDef.meta;
    const dayPP = cell.column.columnDef.accessorKey;
    const idPP = `${idProduct}_${datePP}`;
    setToTransferPP({ idProduct, datePP, dayPP, idPP });
  };

  const handlePp = (cell) => {    
    if(transferMode) {
      handleTransferPP(cell);
    } else if(!chargedConcreteMachining){
        setError("Se debe carga la cantidad de maquinadas de hormigon del día para poder cargar productos en proceso");
      } else {
        setError(false);
        handleUpgradePP(cell);
      }
  };

  const handleModalUpgradePPClose = () => {
    setUpgradePP(null);
  };

  const handleModalToTransferPPClose = () => {
    setToTransferPP(null);
  };

  const handleModalTransferStockClose = () => {
    setOpenModalTransferStock(false);
  };

  const handleTransferMode = (e) => {
    setTransferMode(e.target.checked);
  };

  const selectionTransferToStock = (_toTransferPP) => (!transferToStock.length) ? [] : transferToStock.filter((item) => item.FECHA_INICIO_PRODUCCION === _toTransferPP.datePP && item.ID_PRODUCTO === _toTransferPP.idProduct);

  const actions = [
    {
        icon: <RemoveCircleOutlineIcon color='primary' fontSize='medium'/>,
        tooltip: "Quitar producto en proceso",
        onClick: (event, rowData) => {
          handleModalRemove(rowData.ID_PRODUCTO);
        },
        hidden: !rolUpdateStock,
    },
  ];

  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Productos en Proceso: Poste">
      <Container maxWidth="xl" disableGutters>  
        {openModalWeekPicker && 
          <WeekPickerModal 
            handleModalClose={handleModalWeekPickerClose} 
            openModal={openModalWeekPicker}
            setDate={setDate} 
            date={date}
            daysToMark
            urlDaysToMark={'/production/calendar/days'}
            token={token}
            type={type}
          />   
        }    
        {openModalConcreteMachining && 
          <ConcreteMachining 
            handleModalClose={handleModalConcreteMachiningClose} 
            openModal={openModalConcreteMachining}
            success={successConcreteMachining}
          />   
        }    
        {!!upgradePP && 
         <UpgradePPPostePlacaForm 
          openModal={!!upgradePP}
          handleModalClose={handleModalUpgradePPClose}         
          upgradePP={upgradePP}
          success={successUpgradePP}
         />     
        }
        {!!toTransferPP && 
         <ToTransferPPForm 
          openModal={!!toTransferPP}
          handleModalClose={handleModalToTransferPPClose}         
          toTransferPP={toTransferPP}
          success={successToTransferPP}
          selectionTransferToStock={selectionTransferToStock(toTransferPP)}
         />     
        }
        {openModalTransferStock && 
         <TransferStockForm 
          openModal={openModalTransferStock}
          handleModalClose={handleModalTransferStockClose}         
          transferToStock={transferToStock}
          success={successTransferStock}
          type={type}
         />     
        }
        {openModalAdd && 
         <AddPPForm 
          openModal={openModalAdd}
          handleModalClose={handleModalAddClose}         
          success={successAdd}   
          type={type}
         />     
        }
        {openModalRemove && 
         <RemovePP 
          openModal={openModalRemove}
          handleModalClose={handleModalRemoveClose}     
          removedProductId={removedProductId}    
          success={successRemove}
         />     
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom pb={{ xs: 1, sm: 0 }} textAlign={{xs: "center"}}>
            {isDesktop ? "Productos en Proceso" : "PP"}: Poste
            <Typography variant="h5" sx={{ color: theme.palette.primary.light}}>Modo: {(transferMode) ? 'Traspasar a Stock' : 'Cargar en proceso'} </Typography> 
          </Typography>    
          <Button variant="outlined" onClick={handleModalWeekPickerOpen}  sx={{marginBottom: { xs: "5px"}, paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<DateRangeRoundedIcon/>} disabled={transferMode}>
              Cambiar semana
          </Button>   
          <Grid>       
              <Switch onChange={handleTransferMode} checked={transferMode} inputProps={{ 'aria-label': 'controlled' }}/>  
              <Button variant="contained" onClick={handleModalTransferStockOpen} sx={{marginLeft: { xs: 2 , sm: 1}, paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<Iconify icon="icon-park-outline:nested-arrows" />} disabled={!transferMode}>
                <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                  Traspasar Stock
                </Typography>                
              </Button>   
              <Button variant="contained" onClick={handleModalAddOpen} sx={{marginLeft:1, paddingLeft: { xs: "24px" , sm: "16px"}}} startIcon={<Iconify icon="icon-park-outline:add" />} disabled={transferMode}>
                <Typography variant="button" sx={{ display: { xs: "none", sm: "block" } }}>
                  Añadir Producto
                </Typography>
              </Button>   
          </Grid>                
        </Stack>
        {!chargedConcreteMachining && <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent={{ xs:"space-between", sm:"center"}} mb={2} hidden>
          <Button variant="contained" onClick={handleModalConcreteMachiningOpen} startIcon={<Iconify icon="icon-park-outline:shopping-cart-add" />} disabled={chargedConcreteMachining} sx={{marginRight:{sm:9.5}}} >
            Cargar Maquinadas
          </Button>                
        </Stack>}
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
            
        <Card>     
          <Scrollbar>     
            <TableV2 
              columns={ProductColumns(products, date, transferToStock, handlePp)} 
              data={products}
              dataUpdate={dataUpdate}
              actions={actions}
              initializationDataUpdate={initializationDataUpdate}
              dataUpdateFields={dataUpdateFields}
              nameComponent="pp"
              enableTitle
              title={`Semana del ${date.startOf('week').format('DD/MM/YYYY')} al ${date.endOf('week').format('DD/MM/YYYY')}`}
            />      
          </Scrollbar>          
        </Card>      
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>        
    </Page>   
    </>
  );
}
