import { Divider, Box, Paper, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";
import Label from '../../components/Label';
import DataTable from '../../components/DataTable';
import MovementsColumns from '../../data/table/columns/movements';
import DataProduct from '../../data/grid/fields/product';
import MinimumAvailable from "./MinimumAvailable";

ViewProductModal.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    idProduct: PropTypes.number,
    refresh: PropTypes.func.isRequired,
};

export default function ViewProductModal(props) {
    const {handleModalClose, openModal, idProduct, refresh} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [minimumAvailable, setOpenModalMinimumAvailable] = useState(false);
    const [product, setProduct] = useState([]);
    const [movements, setMovements] = useState([]);
    const [isAlambre, setIsAlambre] = useState(false);
    const [isPostePlaca, setIsPostePlaca] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const theme = useTheme();
    const { rolAdmin, token } = JSON.parse(window.localStorage.getItem("user"));

    useEffect(()=> {
        const onFetchView = () => {    
            const instance = { 
                method: 'get', 
                url: `/product/${idProduct}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){
                    setProduct(data.data.data)   
                    setIsAlambre(data.data.data.ID_PRODUCTO_TIPO === 1)   
                    setIsPostePlaca(data.data.data.ID_PRODUCTO_TIPO === 2 || data.data.data.ID_PRODUCTO_TIPO === 3)   
                } else {
                    setError({open: true, title: 'No se pudo cargar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo cargar el producto', msg: handleAxiosError(e)});
            })
        };
    
        const onFetchMovements = () => {
            const instance = { 
                method: 'get', 
                url: `/registration/${idProduct}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setMovements(data.data.data)   
                } else {
                    setError({open: true, title: 'No se pudo cargar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo cargar el producto', msg: handleAxiosError(e)});
            })
        };
        
        onFetchView();
        onFetchMovements();
      }, [setMovements, idProduct, token])

    const handleCloseDeleteProduct = () => {
        setOpenModalDelete(false);
    };

    const handleOpenModalDelete = () => {
        setOpenModalDelete(true);
    };

    const handleDeleteProduct = () => {
        onFetchDelete();
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onFetchDelete = () => {    
        setIsLoading(true);
        const instance = { 
            method: 'delete', 
            url: `/product/${idProduct}?is_logic=1`,
            headers: {
                "x-auth-token": token,
            },
        };
        axios(instance).then((data) => {
            if(data.data.ok){  
                refresh();     
            } else {
                setError({open: true, title: 'No se pudo eliminar el producto', msg: data.data.error});
                handleCloseDeleteProduct();
            }
            setIsLoading(false);
        })
        .catch((e) => {
            setIsLoading(false);
            setError({open: true, title: 'No se pudo eliminar el producto', msg: handleAxiosError(e)});
            handleCloseDeleteProduct();
        })
    };

    const handleModalMinimunAvailableOpen = () => {
        setOpenModalMinimumAvailable(true);
    };

    const handleModalMinimumAvailableClose = () => {
        setOpenModalMinimumAvailable(false);
    };

    const refreshModalMinimumAvailable = () => {
        handleModalMinimumAvailableClose();
        handleModalClose();
    };

    const handleMinimumAvailable = () => {
        handleModalMinimunAvailableOpen();
    };   
    

    const {rowsData, rowsAlambre, rowsPostePlaca, rowsStock, rowsStockDetailFabrica, rowsStockDetailDomenech, rowsStockDetailC3} = DataProduct(product,isAlambre,isPostePlaca,handleMinimumAvailable);

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'xl'} onBackdropClick={handleModalClose}>
        <DialogTitle >
            <Grid container spacing={1} >
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        {product.NOMBRE}    
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={1}>  
                    <Grid container  spacing={0} columns={1} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        {isAlambre && <>                               
                            <Grid item>
                                <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsAlambre.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                                {item.field}                                        
                                            </Typography>
                                        </Grid>
                                    )}                            
                                </Grid>
                            </Grid>                                              
                            <Grid item>      
                                <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                        {rowsAlambre.map((item, index) => 
                                            <Grid item xs={12} sm={1} key={index}>
                                                <Label
                                                    variant={item.variant ?? "filled"}
                                                    color="default"
                                                    sx={{
                                                        justifyContent: 'left',
                                                        textTransform: 'uppercase',
                                                        width: 120,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                                </Label>
                                            </Grid>
                                        )}                            
                                </Grid>  
                            </Grid> 
                            <Grid item ml={1} mr={1}>
                                <Divider orientation="vertical"/>
                            </Grid>
                        </>}
                        {isPostePlaca && <>                               
                            <Grid item>
                                <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsPostePlaca.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                                {item.field}                                        
                                            </Typography>
                                        </Grid>
                                    )}                            
                                </Grid>
                            </Grid>                                              
                            <Grid item>      
                                <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                        {rowsPostePlaca.map((item, index) => 
                                            <Grid item xs={12} sm={1} key={index}>
                                                <Label
                                                    variant={item.variant ?? "filled"}
                                                    color="default"
                                                    sx={{
                                                        justifyContent: 'left',
                                                        textTransform: 'uppercase',
                                                        width: 120,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                                </Label>
                                            </Grid>
                                        )}                            
                                </Grid>  
                            </Grid> 
                            <Grid item ml={1} mr={1}>
                                <Divider orientation="vertical"/>
                            </Grid>
                        </>}
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsData.map((item, index) => 
                                    <Grid item xs={12} sm={1} key={index}>
                                        <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                            {item.field}                                        
                                        </Typography>
                                    </Grid>
                                )}                            
                            </Grid>
                        </Grid>                         
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsData.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    justifyContent: 'left',
                                                    textTransform: 'uppercase',
                                                    width: 220,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>                                                   
                        <Grid item ml={1} mr={1}>
                            <Divider orientation="vertical"/>
                        </Grid>                                       
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsStock.map((item, index) => 
                                    <Grid item xs={12} sm={1} key={index}>
                                        <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                            {item.field}                                   
                                        </Typography>
                                    </Grid>
                                )}                            
                            </Grid>
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStock.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            {!item.action && 
                                                <Label
                                                    variant={item.variant ?? "filled"}
                                                    color="primary"
                                                    sx={{
                                                        textTransform: 'uppercase',
                                                        width: 100,
                                                    }}
                                                >
                                                    {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                                </Label>
                                            }
                                            {item.action && 
                                                <Button 
                                                    variant={item.variant ?? "filled"} 
                                                    onClick={() => item.onclick()}
                                                    sx={{
                                                        textTransform: 'uppercase',
                                                        width: 100,
                                                        height: 22,
                                                    }}
                                                    >
                                                    {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                                </Button>
                                            }
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailFabrica.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailDomenech.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>              
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailC3.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>              
                        <Grid item xs={12} sm={12} mt={2}>
                            <DataTable
                                columns={MovementsColumns()}
                                data={movements}
                                disableColumnMenu
                                styleHeader={{
                                    backgroundColor:theme.palette.primary.main, 
                                    color:"white",
                                }}              
                                height={400}                  
                            />
                        </Grid>
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>
            <Grid container mr={3} ml={3} mb={1} mt={1}>    
                <Grid item xs={12} sm={6}>                             
                    {rolAdmin && 
                        <Button variant="text" color="error" onClick={handleOpenModalDelete} >
                            Eliminar
                        </Button>
                    }
                </Grid>   
                <Grid item xs={12} sm={6} align="right">                           
                    <Button variant="contained" color="primary" onClick={handleModalClose}>
                        Cerrar
                    </Button>  
                </Grid>   
            </Grid>  
        </DialogActions>             
        <Dialog
            open={openModalDelete}
            onClose={handleCloseDeleteProduct}
            onBackdropClick={handleCloseDeleteProduct}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Eliminar producto"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"¿Esta seguro que desea eliminar el producto?"}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button color="error" disabled={isLoading} onClick={handleDeleteProduct} >
                    Eliminar
                </Button>
                <Button variant="outlet" color="secondary" onClick={handleCloseDeleteProduct}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        {minimumAvailable && 
         <MinimumAvailable 
          handleModalClose={handleModalMinimumAvailableClose} 
          openModal={minimumAvailable}
          idProduct={idProduct}
          units={product.STOCK_MINIMO_DISPONIBLE}
          refresh={refreshModalMinimumAvailable}
         />
        }
    </Dialog> 
    );
}
