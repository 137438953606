/* eslint-disable react/prop-types */
import { Button, Typography, Zoom, Tooltip, Grid, TextField, InputAdornment } from '@mui/material';
import { dateToDatesWeek } from '../../../utils/helpers';

export default (products, date, merma, percentageMerma, handlePp, handleMerma, submitMerma) => {
  const datesWeek = dateToDatesWeek(date);
  
  const tooltipCompleteText = (cell) => (
    <Tooltip 
      title={cell.getValue()} 
      TransitionComponent={Zoom} 
      placement="top" 
      arrow 
      describeChild 
      enterDelay={2500} 
      leaveDelay={100}
    >
      <Typography 
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {cell.getValue()}
      </Typography>
    </Tooltip>    
  );
    
  const text = (cell, color = null) => <Typography align="center" color={color}>{cell.getValue()}</Typography>;
  
  const pp = (cell, color = null) => {    
    const tooltip = '';     
    return(
      <Tooltip 
        title={`${tooltip}`} 
        TransitionComponent={Zoom} 
        placement="top" 
        arrow 
        describeChild 
        enterDelay={1000} 
        leaveDelay={100}         
      >
      <Button variant="text" onClick={() => handlePp(cell)}>
        <Typography align="center" color={color}>
          {cell.getValue()}
        </Typography>
      </Button>
    </Tooltip>
  )};
 
  const buttonTotalFooter = (item, color = null) => (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <Button variant="text">
          <Typography align="center" color={color}>
            {products.reduce((acc, curr) => acc + curr[item], 0)}
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button variant="text" >
          <Typography align="center" color={color}>
            {(merma) ? merma[item] : 0}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )

  const onChangeMerma = (e) => {    
    const min = 0;
    const max = 100;
    const { value } = e.target;
    if(value){
      let percentage = parseFloat(value);
      if (percentage > max) percentage = max;
      if (percentage < min) percentage = min;
      handleMerma(percentage);
    } else {
      handleMerma(value);
    }
  }
  
  const nameFooter = () => 
     (
    <Grid container direction="column" align="right" rowSpacing={1}>
      <Grid item>
        <Typography align="right" mt={0.8} mr={5}>
          Totales
        </Typography>
      </Grid>
      <Grid item rowSpacing={1}>
      <Button onClick={submitMerma} sx={{ marginTop: 1.6, marginRight: 1,}}>
        Guardar
      </Button>
        <TextField 
          id="merma" 
          label="Merma" 
          name="merma"
          hiddenLabel
          variant="outlined" 
          type="number"
          size="small"  
          margin="dense"   
          sx={{
            width: 100,
            marginTop: 1.5,
            marginRight: 0,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}  
          onChange={onChangeMerma}
          value={percentageMerma ?? ''}
        />     
      </Grid>
    </Grid>
  );

  return [
    { 
      accessorKey: 'ID_PRODUCTO', 
      header: 'Id', 
      size: 1,
      enableClickToCopy: false,
      enableColumnDragging: false,
      enableGrouping: false,
      enableColumnFilter: false,
      enableEditing: false,
      muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
      Cell: ({ cell }) => text(cell),
    },
    { 
      accessorKey: 'NOMBRE', 
      header: 'Nombre', 
      size: 20,
      minSize: 10,
      maxSize: 30,
      enableGrouping: false,
      enableClickToCopy: false,   
      enableEditing: false, 
      muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
      Cell: ({ cell }) => tooltipCompleteText(cell),
      Footer: () => nameFooter(),
    },
    { 
      accessorKey: 'LUNES', 
      meta: datesWeek.LUNES.iso, 
      header: datesWeek.LUNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell}) => pp(cell),
      Footer: () => buttonTotalFooter('LUNES'),
    },
    { 
      accessorKey: 'MARTES', 
      meta: datesWeek.MARTES.iso,
      header: datesWeek.MARTES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('MARTES'),
    },
    { 
      accessorKey: 'MIERCOLES', 
      meta: datesWeek.MIERCOLES.iso,
      header: datesWeek.MIERCOLES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('MIERCOLES'),
    },
    { 
      accessorKey: 'JUEVES', 
      meta: datesWeek.JUEVES.iso,
      header: datesWeek.JUEVES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('JUEVES'),
    },
    { 
      accessorKey: 'VIERNES', 
      meta: datesWeek.VIERNES.iso,
      header: datesWeek.VIERNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('VIERNES'),
    },
    { 
      accessorKey: 'SABADO', 
      meta: datesWeek.SABADO.iso,
      header: datesWeek.SABADO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('SABADO'),
    },
    { 
      accessorKey: 'DOMINGO', 
      meta: datesWeek.DOMINGO.iso,
      header: datesWeek.DOMINGO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('DOMINGO'),
    }, 
  ];
}