/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

function RadioButtonGroup(props) {
  const { title, defaultValue, options, onChange, row } = props;
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  return (
    <FormControl component="fieldset">
      {title && 
        <FormLabel>
          <Typography variant="subtitle2" gutterBottom sx={{mt:1, mb:0}}>
            {title}
          </Typography>              
        </FormLabel>
      }
      <RadioGroup
        row={row}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={defaultValue}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <FormControlLabel 
            key={index} 
            value={option.value} 
            control={<Radio size="small"/>} 
            label={option.label} 
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonGroup;