/* eslint-disable react/prop-types */
import { Typography, Chip } from '@mui/material';

import { fDateTimeSuffix } from "../../../utils/formatTime";

const text = (cell, color = null, align = "left") => <Typography align={align} color={color}>{cell.getValue()}</Typography>;

const date = (cell, color = null, align = "left") => <Typography align={align} color={color}>{fDateTimeSuffix(cell.getValue())}</Typography>;

const status = (cell) => {
  const status = !!cell.getValue();
  const color = (status) ? 'success' : 'warning';
  const label = (status) ? 'CONFIRMADO' : 'PENDIENTE';
  return <Chip color={color} label={label} variant="filled"/> 
};

export default () => [
  { 
    accessorKey: 'NUMERO', 
    header: 'Numero de Remito', 
    enableClickToCopy: false,
    enableColumnDragging: false,
    enableGrouping: false,
    enableColumnFilter: false,
    muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
    Cell: ({ cell }) => text(cell),
  },
  { 
    accessorKey: 'FECHA_EMISION', 
    header: 'Fecha de Emisión', 
    enableClickToCopy: false,
    enableColumnDragging: false,
    enableGrouping: false,
    enableColumnFilter: false,   
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) => date(cell),
  },  
  { 
    accessorKey: 'CONFIRMADO', 
    header: 'Estado', 
    size: 20,
    minSize: 10,
    maxSize: 30,
    enableClickToCopy: false,
    enableColumnDragging: false,
    enableGrouping: false,
    enableColumnFilter: false,    
    muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
    Cell: ({ cell }) =>  status(cell),
  },  
];
