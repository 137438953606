import { Box, Paper, TextField, Divider, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import PpColumns from '../../data/table/columns/pp';

import axios, { handleAxiosError } from "../../config/axios";

UpgradePPPostePlacaForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    upgradePP: PropTypes.object.isRequired,
    success: PropTypes.func.isRequired,
};

export default function UpgradePPPostePlacaForm(props) {
    const {success, handleModalClose,openModal,upgradePP} = props;
    
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [pp, setPp] = useState([]);  
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const theme = useTheme();

    const ID_SUPERVISORES = 3000;
    const validationSchema = Yup.object().shape({
        id_producto: Yup.number().integer().positive().min(1).max(99999).required('El código es requerido').default(upgradePP.idProduct),
        fecha_inicio_produccion: Yup.string().required('La fecha de inicio de producción es requerida').default(upgradePP.datePP),
        cantidad: Yup.number().positive().min(0.1).max(99999999.9).required('La cantidad es requerida'),
        id_empleado: Yup.number().integer().positive().min(1).required('El empleado es requerido').default(ID_SUPERVISORES),
    });
    const { register, clearErrors, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });    
    const { token } = JSON.parse(window.localStorage.getItem("user"));

    const maximumFieldLength = {
        cantidad: 10,
    };       

    useEffect(() => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
            })
        };
        const onFetchViewPP = () => {
            const instance = { 
                method: 'get', 
                url: `/production/${upgradePP.idPP}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setPp(data.data.data)   
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: handleAxiosError(e)});
            })
        };

        onFetchView(upgradePP.idProduct);
        onFetchViewPP(upgradePP.idPP);        

    }, [token, upgradePP.idPP, upgradePP.idProduct]);

    const handleLimit = (e) => {
        const maxLength = maximumFieldLength[e.target.id];
        if(e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }
    };      

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = (data) => {
        onFetchRegister(data);
        clearErrors();
    };

    const onFetchRegister = (data) => {   
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: '/production',
            headers: {
                "x-auth-token": token,
            },
            data,
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo cargar la cantidad en proceso', msg: data.data.error});
            }
        })
        .catch((e) => {           
            setError({open: true, title: 'No se pudo cargar la cantidad en proceso', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };

    const handleDelete = (row) => {    
        setIsLoading(true);
        const instance = { 
            method: 'delete', 
            url: '/production',
            headers: {
                "x-auth-token": token,
            },
            data: {
                id_producto: row.ID_PRODUCTO,
                id_empleado: row.ID_EMPLEADO,
                fecha_inicio_produccion: row.FECHA_INICIO_PRODUCCION,
            }
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo deshacer las unidades en proceso', msg: data.data.error});
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo deshacer las unidades en proceso', msg: handleAxiosError(e)});           
            setIsLoading(false);
        })
    };


    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Cargar Producto en Proceso  |  {product.NOMBRE} | {upgradePP.dayPP} {upgradePP.datePP}   
                    </Typography>                       
                </Grid>
                <Grid item xs={12} sm={1} align="right">                    
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={1}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >                        
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="cantidad"
                                        name="cantidad"
                                        label="Cantidad"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        onInput={handleLimit}
                                        {...register('cantidad')}
                                        error={!!errors.cantidad}
                                    />
                                </Grid>                                
                               
                                <Grid container columnSpacing={1}>                                     
                                    <Grid item xs={12} sm={12} mb={0} mt={2}>
                                        <Divider/>
                                    </Grid>   
                                </Grid> 
                                <Grid item xs={12} sm={12} mt={2}>
                                    <DataTable
                                        columns={PpColumns(handleDelete)}
                                        data={pp}
                                        disableColumnMenu
                                        disableToolbar
                                        disableFooter
                                        styleHeader={{
                                            backgroundColor:theme.palette.primary.main, 
                                            color:"white",
                                        }}                
                                        height={250}     
                                    />
                                </Grid>                                
                            </Grid>   
                        </Grid>                      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={0}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary"  disabled={isLoading} onClick={handleSubmit(onSubmit)} >
                        Cargar
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>    
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
