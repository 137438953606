import { Snackbar, Alert, InputAdornment, Box, Paper, TextField, Divider, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useCallback, useEffect  } from 'react';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";
import EditableInput from '../../components/EditableInput';

EditProductForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    idProduct: PropTypes.number,
    maxLengthIdProduct: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.arrayOf(PropTypes.object).isRequired,
    unitsMeasure: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function EditProductForm(props) {
    const {handleModalClose,openModal,refresh,idProduct,maxLengthIdProduct,types,subtypes,classes,unitsMeasure} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const [product, setProduct] = useState([]);
    const [isAlambre, setIsAlambre] = useState(false);
    const [isPostePlaca, setIsPostePlaca] = useState(false);
    const [idProductType, setIdProductType] = useState(null); 
    const [searchedProduct, setSearchedProduct] = useState('');
    const [disabledSearchProduct, setDisabledSearchProduct] = useState(false);
    const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});



    const { token } = JSON.parse(window.localStorage.getItem("user"));

    const maximumFieldLength = {
        id_producto: maxLengthIdProduct,
    };  

    const handleLimit = (e) => {
        const maxLength = maximumFieldLength[e.target.id];
        if(e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }
    }; 

    const handleOnInputIdProduct = (e) => {
        handleLimit(e)
        handleChangeIdProduct(e.target.value);
    };

    const handleChangeTypeProduct = (id) => {
        setIdProductType(id)
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const handleUpdate = (value, name) => {
        const data = {};
        data[name] = value;
        onFetchCreate(data);
    };

    const onFetchCreate = (data) => {    
        setIsLoading(true);
        const instance = { 
            method: 'patch', 
            url: `/product/${product.ID_PRODUCTO}`,
            headers: {
            "x-auth-token": token,
            },
            data,
        };
        axios(instance).then((data) => {
            if(data.data.ok){  
                setOpenSuccess({open: true, msg: "Se actualizo el producto correctamente"}); 
            } else {
                setError({open: true, title: 'No se pudo editar el producto', msg: data.data.error});
            }
            setIsLoading(false);
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo editar el producto', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };

    const handleChangeIdProduct = useCallback((id) => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setIdProductType(data.data.data.ID_PRODUCTO_TIPO);
                    setProduct(data.data.data);     
                    setIsAlambre(data.data.data.ID_PRODUCTO_TIPO === 1);  
                    setIsPostePlaca(data.data.data.ID_PRODUCTO_TIPO === 2 || data.data.data.ID_PRODUCTO_TIPO === 3) 
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
            })
        };
        if(id === ''){
            setSearchedProduct('');
            setProduct([]);
        } else {
            setSearchedProduct(id);
            onFetchView(id);
        }
    }, [token]);

    useEffect(() => {
        if(idProduct){
            handleChangeIdProduct(idProduct);
            setDisabledSearchProduct(true);
        }
    }, [handleChangeIdProduct, idProduct]);

    const handleSuccess = (event, reason) => {
        if (reason === 'clickaway') return;    
        setOpenSuccess({open: false, msg: ""});
      };  

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
        <Grid container spacing={1} >
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1 }}>
                        Editar Producto
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={1} py={0}>               
                    <Grid container  spacing={0} columns={1} rowSpacing={2} columnSpacing={2}  justifyContent="flex-start">                         
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                value={searchedProduct}
                                disabled={disabledSearchProduct}
                                id="id_producto"
                                name="id_producto"
                                label="Código"
                                type="number"
                                margin="dense"
                                onInput={handleOnInputIdProduct}
                            />
                        </Grid>  
                        <Grid item xs={12} sm={12}>
                            <Divider/>
                        </Grid> 
                        <Grid item  xs={12} sm={12}>
                            <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="nombre"
                                        label="Nombre"
                                        name="nombre"
                                        value={product.NOMBRE}
                                        margin="dense"
                                        onSave={handleUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="id_producto_clase"
                                        name="id_producto_clase"
                                        label="Clase"
                                        margin="dense"
                                        defaultValue={product.ID_PRODUCTO_CLASE}
                                        onSave={handleUpdate}
                                        select
                                        options={classes}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={12}>
                            <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="id_producto_tipo"
                                        name="id_producto_tipo"
                                        label="Tipo"
                                        margin="dense"
                                        defaultValue={product.ID_PRODUCTO_TIPO}
                                        onSave={handleUpdate}
                                        select
                                        options={types}
                                        onChangeSelect={handleChangeTypeProduct}
                                        disabled={isAlambre || isPostePlaca}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="id_producto_subtipo"
                                        name="id_producto_subtipo"
                                        label="Subtipo"
                                        margin="dense"
                                        defaultValue={product.ID_PRODUCTO_SUBTIPO}
                                        onSave={handleUpdate}
                                        select
                                        options={subtypes.filter(option => option.parent_value === idProductType)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="id_producto_unidad_medida"
                                        name="id_producto_unidad_medida"
                                        label="Unidad Medida"
                                        margin="dense"
                                        defaultValue={product.ID_PRODUCTO_UNIDAD_MEDIDA}
                                        onSave={handleUpdate}
                                        select
                                        options={unitsMeasure}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <EditableInput
                                        id="descripcion"
                                        name="descripcion"
                                        label="Descripción"
                                        value={product.DESCRIPCION}
                                        margin="dense"
                                        onSave={handleUpdate}
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>                        
                        {isAlambre && <>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid> 
                            <Grid item  xs={12} sm={6}>
                                <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >
                                    <Grid item xs={12} sm={6}>
                                        <EditableInput
                                            id="rombo"
                                            name="rombo"
                                            label="Rombo"
                                            value={product.ROMBO}
                                            margin="dense"
                                            onSave={handleUpdate}
                                        />
                                    </Grid>                                
                                    <Grid item xs={12} sm={6}>
                                        <EditableInput
                                            id="calibre"
                                            name="calibre"
                                            label="Calibre"
                                            value={product.CALIBRE}
                                            margin="dense"
                                            onSave={handleUpdate}
                                        />
                                    </Grid>                                                           
                                </Grid>
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >
                                    <Grid item xs={12} sm={6}>
                                        <EditableInput
                                            id="altura"
                                            name="altura"
                                            label="Altura"
                                            value={product.ALTURA}
                                            margin="dense"
                                            onSave={handleUpdate}
                                        />
                                    </Grid>                                
                                    <Grid item xs={12} sm={6}>
                                        <EditableInput
                                            id="kg_x_rollo"
                                            name="kg_x_rollo"
                                            label="Kg por Rollo"
                                            value={product.KG_X_ROLLO}
                                            margin="dense"
                                            onSave={handleUpdate}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                            }}
                                        />
                                    </Grid>                                
                                </Grid>
                            </Grid>
                        </>}                             
                        {isPostePlaca && <>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid>                             
                            <Grid item  xs={12} sm={6}>
                                <Grid container direction="row" alignItems="flex-start" rowSpacing={1} >                                    
                                    <EditableInput
                                        id="kg_x_unidad"
                                        name="kg_x_unidad"
                                        label="Kg por Unidad"
                                        value={product.KG_X_UNIDAD}
                                        margin="dense"
                                        onSave={handleUpdate}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                        }}
                                    />                              
                                </Grid>
                            </Grid>
                        </>}                             
                    </Grid>          
                </Box>
                <Snackbar 
                    open={openSuccess.open} 
                    autoHideDuration={1500} 
                    onClose={handleSuccess} 
                    anchorOrigin={{ 
                        vertical: 'bottom', 
                        horizontal: 'right' 
                    }}
                >
                    <Alert onClose={handleSuccess} severity="success" >
                        {openSuccess.msg}
                    </Alert>
                </Snackbar>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={1}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary"  onClick={handleModalClose} sx={{marginLeft:1}}>
                        Volver
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>             
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    </Dialog> 
    );
}
