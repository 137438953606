function getToDiscount(params) {
  return params.row.CANTIDAD_NOMINAL - params.row.CANTIDAD_REAL;
}

function setToDiscount(params) {  
  return params.row.CANTIDAD_NOMINAL - params.row.CANTIDAD_REAL;
}

export default () => [  
  { 
    field: 'NOMBRE_EMPLEADO', 
    headerName: 'Empleado', 
    width: 329,
    description: 'Empleado productor',
    sortable: false,
  },
  { 
    field: 'NOMBRE_PRODUCTO', 
    headerName: 'Producto', 
    width: 320,
    description: 'Producto',
    sortable: false,
  },
  { 
    field: 'CANTIDAD_NOMINAL', 
    headerName: 'Cantidad Nominal', 
    width: 150,
    type: 'number',
    description: 'Unidades que figuran en el remito interno',
    sortable: false,
  },
  { 
    field: 'CANTIDAD_REAL', 
    headerName: 'Cantidad Real', 
    width: 150,
    type: 'number',
    description: 'Unidades fisicas reales entregadas',
    editable: true, 
    sortable: false,
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value > params.row.CANTIDAD_NOMINAL || params.props.value < 0;
      return { ...params.props, error: hasError };
    },
  },
  { 
    field: 'A_DESCONTAR', 
    headerName: 'A Descontar', 
    width: 150,
    type: 'number',
    description: 'Unidades a dar de baja',
    sortable: false,
    valueGetter: getToDiscount,
    valueSetter: setToDiscount,
  }, 
];
