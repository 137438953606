import { Box, Paper, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import PphColumns from '../../data/table/columns/pph';

import axios, { handleAxiosError } from "../../config/axios";

DetailsPPH.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    detailsPP: PropTypes.object.isRequired,
};

export default function DetailsPPH(props) {
    const { handleModalClose,openModal,detailsPP } = props;
    
    const [product, setProduct] = useState([]);
    const [pp, setPp] = useState([]); 
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const theme = useTheme();
     
    const { token } = JSON.parse(window.localStorage.getItem("user"));

    useEffect(() => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
            })
        };
        const onFetchViewPP = () => {
            const instance = { 
                method: 'get', 
                url: `/production/${detailsPP.idPP}?history=true`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setPp(data.data.data)   
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto en proceso', msg: handleAxiosError(e)});
            })
        };

        onFetchView(detailsPP.idProduct);
        onFetchViewPP(detailsPP.idPP);        

    }, [token, detailsPP.idPP, detailsPP.idProduct]);

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };
   
    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Detalle Producto en Proceso  |  {product.NOMBRE} | {detailsPP.dayPP} {detailsPP.datePP}   
                    </Typography>                       
                </Grid>
                <Grid item xs={12} sm={1} align="right">                    
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={1}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >                                
                                <Grid item xs={12} sm={12} mt={2}>
                                    <DataTable
                                        columns={PphColumns()}
                                        data={pp}
                                        disableColumnMenu
                                        disableToolbar
                                        disableFooter
                                        styleHeader={{
                                            backgroundColor:theme.palette.primary.main, 
                                            color:"white",
                                        }}                
                                        height={250}     
                                    />
                                </Grid>                                
                            </Grid>   
                        </Grid>                      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={0}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>    
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
