export const daysWeek = ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO'];   

export function dateToDatesWeek(date) { 
    const datesWeek = {};
    daysWeek.forEach((day, i)=> {
        const dayCC = toCamelCase(day);
        datesWeek[day] = {
            nameDayAndNumber: `${dayCC} ${date.startOf('week').add(i, 'day').format('DD')}`, 
            day: date.startOf('week').add(i, 'day').format('DD'), 
            month: date.startOf('week').add(i, 'day').format('MM'), 
            year: date.startOf('week').add(i, 'day').format('YYYY'), 
            y: date.startOf('week').add(i, 'day').format('YY'), 
            iso: date.startOf('week').add(i, 'day').format('YYYY-MM-DD'), 
            arg: date.startOf('week').add(i, 'day').format('DD-MM-YYYY'), 
        }
    })
    return datesWeek;
}

export function toCamelCase(str) {
    return str.toLocaleUpperCase().substring(0, 1)+str.toLocaleLowerCase().substring(1);
}