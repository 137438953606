/* eslint-disable react/prop-types */
import { Button, Typography, Zoom, Tooltip } from '@mui/material';
import { dateToDatesWeek } from '../../../utils/helpers';

export default (products, date, handlePp) => {
  const datesWeek = dateToDatesWeek(date);
  
  const tooltipCompleteText = (cell) => (
    <Tooltip 
      title={cell.getValue()} 
      TransitionComponent={Zoom} 
      placement="top" 
      arrow 
      describeChild 
      enterDelay={2500} 
      leaveDelay={100}
      
    >
      <Typography 
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {cell.getValue()}
      </Typography>
    </Tooltip>    
  );
    
  const text = (cell, color = null) => <Typography align="center" color={color}>{cell.getValue()}</Typography>;
  
  const pp = (cell, color = null) => {    
    const tooltip = '';     
    return(
      <Tooltip 
        title={`${tooltip}`} 
        TransitionComponent={Zoom} 
        placement="top" 
        arrow 
        describeChild 
        enterDelay={1000} 
        leaveDelay={100}         
      >
      <Button variant="text" onClick={() => handlePp(cell)}>
        <Typography align="center" color={color}>
          {cell.getValue()}
        </Typography>
      </Button>
    </Tooltip>
  )};
 
  const buttonTotalFooter = (item, color = null) => (
    <Button variant="text">
      <Typography align="center" color={color}>
        {products.reduce((acc, curr) => acc + curr[item], 0)}
      </Typography>
    </Button>
  )

  const nameFooter = () => (
    <Typography align="right" mt={0.8}>
      Totales
    </Typography>
  );

  return [
    { 
      accessorKey: 'ID_PRODUCTO', 
      header: 'Id', 
      size: 1,
      enableClickToCopy: false,
      enableColumnDragging: false,
      enableGrouping: false,
      enableColumnFilter: false,
      enableEditing: false,
      muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
      Cell: ({ cell }) => text(cell),
    },
    { 
      accessorKey: 'NOMBRE', 
      header: 'Nombre', 
      size: 20,
      minSize: 10,
      maxSize: 30,
      enableGrouping: false,
      enableClickToCopy: false,   
      enableEditing: false, 
      muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
      Cell: ({ cell }) => tooltipCompleteText(cell),
      Footer: () => nameFooter(),
    },
    { 
      accessorKey: 'LUNES', 
      meta: datesWeek.LUNES.iso, 
      header: datesWeek.LUNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell}) => pp(cell),
      Footer: () => buttonTotalFooter('LUNES'),
    },
    { 
      accessorKey: 'MARTES', 
      meta: datesWeek.MARTES.iso,
      header: datesWeek.MARTES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('MARTES'),
    },
    { 
      accessorKey: 'MIERCOLES', 
      meta: datesWeek.MIERCOLES.iso,
      header: datesWeek.MIERCOLES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('MIERCOLES'),
    },
    { 
      accessorKey: 'JUEVES', 
      meta: datesWeek.JUEVES.iso,
      header: datesWeek.JUEVES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('JUEVES'),
    },
    { 
      accessorKey: 'VIERNES', 
      meta: datesWeek.VIERNES.iso,
      header: datesWeek.VIERNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('VIERNES'),
    },
    { 
      accessorKey: 'SABADO', 
      meta: datesWeek.SABADO.iso,
      header: datesWeek.SABADO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('SABADO'),
    },
    { 
      accessorKey: 'DOMINGO', 
      meta: datesWeek.DOMINGO.iso,
      header: datesWeek.DOMINGO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell),
      Footer: () => buttonTotalFooter('DOMINGO'),
    }, 
  ];
}