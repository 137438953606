/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, Typography, FormGroup, FormControlLabel, FormControl, FormLabel} from '@mui/material';

function CheckboxGroup(props) {
    const { title, options, onChange, labelPlacement } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
  
    const handleCheckboxChange = (option) => {
      setSelectedKeys(prevSelectedKeys => {
        if (prevSelectedKeys.includes(option)) {
          return prevSelectedKeys.filter(selectedKey => selectedKey.value !== option.value);
        } 
          return [...prevSelectedKeys, option];
        
      });
    };
  
    useEffect(() => {
      onChange(selectedKeys);
    }, [onChange, selectedKeys]);

    return (
      <FormControl component="fieldset">
        {title && 
          <FormLabel>
            <Typography variant="subtitle2" gutterBottom sx={{mt:1, mb:0}}>
              {title}
            </Typography>              
          </FormLabel>
        }
        <FormGroup row>
          <Grid container spacing={0} columnSpacing={3}>
          {options.map((option, index) => (
             <Grid item xs={12} sm={(options.length > 3) ? 3 : 4} key={index}>
              <FormControlLabel
                key={option.label + option.value}
                value="end"
                control={<Checkbox size="small"/>}
                label={<span style={{ fontSize: '0.875rem', lineHeight: '1.25rem', width: '30rem' }}>{option.label}</span>}
                labelPlacement={labelPlacement}
                onChange={() => handleCheckboxChange(option)}
              />
            </Grid>
          ))}
          </Grid>
        </FormGroup>
      </FormControl>
    );
  }
export default CheckboxGroup;