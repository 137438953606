 
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
    
import Page from '../components/Page';
import { PasswordForm } from '../sections/auth/password';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function PasswordUpdate() {
  
  return (
    <Page title="PasswordUpdate">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <PasswordForm/>          
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}