import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
    
import Page from '../components/Page';
import Logo from '../components/Logo';

import { LoginForm } from '../sections/auth/login';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
          <Logo disabledLink sx={{ width: 476, height: 117, marginBottom: 4 }} />   
            <LoginForm />           
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
/* #6A67B8  */