
import { useState, useEffect, useCallback } from 'react';
import { Card, Stack, Container, Typography, Alert, Snackbar } from '@mui/material';
import axios, { handleAxiosError } from '../config/axios';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import TableV2 from '../components/TableV2';
import LoadBackdrop from '../components/LoadBackdrop';
import ProductColumns from '../data/table/columns/minimumstockexcedeed';

export default function MinimumStockExcedeed() {  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [products, setProducts] = useState([]);
  /* const [dataUpdate, setDataUpdate] = useState(null);   */
  const { token } = JSON.parse(window.localStorage.getItem("user"));

  const onFetchProducts = useCallback(() => {   
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: '/product/minimum_stock/exceeded',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [token]);  

  
  useEffect(()=> {
    onFetchProducts();
  }, [onFetchProducts])
 

  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenSuccess({open: false, msg: ""});
  };  

  /* const initializationDataUpdate = () => {
    setDataUpdate(null);
  } */

  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Minimo Stock Superado">
      <Container maxWidth="xl" disableGutters>      
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom textAlign={{xs: "center"}}>
            Minimo Stock Superado
          </Typography>                   
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
        <Card>                
          <Scrollbar>     
            <TableV2 
              columns={ProductColumns(products)} 
              data={products}
              /* dataUpdate={dataUpdate}
              initializationDataUpdate={initializationDataUpdate}
              urlExportPdf='production/download/pdf'
              nameComponent="production"
              enableExports */
            />      
          </Scrollbar>          
        </Card>      
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>        
    </Page>   
    </>
  );
}
