export default () => [
   { 
    field: 'A_TRASPASAR', 
    headerName: 'A traspasar', 
    width: 107,
    type: 'number',
    description: 'Unidades a traspasar a Stock',
    sortable: false, 
    editable: true, 
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value > params.row.CANTIDAD || params.props.value < 0;
      return { ...params.props, error: hasError };
    },
  },
   { 
    field: 'CANTIDAD', 
    headerName: 'En proceso', 
    width: 108,
    type: 'number',
    description: 'Unidades en proceso',
  },
  { 
    field: 'NOMBRE_COMPLETO', 
    headerName: 'Empleado', 
    width: 587,
    description: 'Empleado productor',
  }, 
];
