
import { useState, useEffect, useCallback } from 'react';
import { MenuItem, TextField, Grid, Button, Card, Stack, Container, Typography, Alert, Snackbar, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText } from '@mui/material';
import DataTableEditTemporal from '../components/DataTableEditTemporal';
import axios, { handleAxiosError } from '../config/axios';
import useResponsive from "../hooks/useResponsive";
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import LoadBackdrop from '../components/LoadBackdrop';
import StockColumns from '../data/table/columns/transportproducts';

export default function TransportProducts() {  
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setModalError] = useState({open: false, title: '', msg: ''});
  const [error, setError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({open: false, msg: ''});
  const [products, setProducts] = useState([]);
  const [transportProducts, setTransportProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { rolUpdateStock, token } = JSON.parse(window.localStorage.getItem("user"));
  const [ listeningStream , setListeningStream ] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [depositSelect, setDepositSelected] = useState(1);

  const isDesktop = useResponsive('up', 'lg');

  useEffect( () => {
    if (!listeningStream ) {
      const url = `${process.env.REACT_APP_API_URL}/realtimedata/subscribe?xauthtoken=${token}`;
      const events = new EventSource(url);

      events.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const { module } = data;        
        if(module === 'transport'){
          setProducts(data.list);
        }
      };
      setListeningStream(true);
    }
  }, [listeningStream, token]);


  const onFetchProducts = useCallback(() => {   
    setIsLoading(true);
    const instance = { 
      method: 'get', 
      url: '/product/produced/factory',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){       
          setProducts(data.data.data);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(handleAxiosError(e));
        setIsLoading(false);
      })
  }, [token]);  

  const onFetchCategorys = useCallback(() => {    
    const instance = { 
      method: 'get', 
      url: '/categories',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){                  
          const filterDeposit = data.data.data.depositos.filter(deposit => deposit.label !== 'FABRICA');
          setDeposits(filterDeposit);
          setDepositSelected(filterDeposit[0].value)
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
      })
      .catch((e) => {
        setError(handleAxiosError(e));
      })
  }, [token]);

  useEffect(()=> {
    onFetchProducts();
    onFetchCategorys();
  }, [onFetchCategorys, onFetchProducts]);

  const onFetchTransportStock = () => {   
    setIsLoading(true);
    const instance = { 
        method: 'post', 
        url: '/production/transport',
        headers: {
            "x-auth-token": token,
        },
        data: { 
          id_deposito_destino: depositSelect,
          products: transportProducts.map(tp => {
            delete tp.id
            return tp;
          }) 
        },
    };
    axios(instance).then((data) => {
        setIsLoading(false);
        if(data.data.ok){  
            onFetchExportPDF(data.data.data);
            handleCloseModal();
            refresh();
        } else {
            setModalError({open: true, title: 'Error al transportar', msg: 'No se pudo transportar el stock' });
            handleCloseModal();
        }
    })
    .catch((e) => {           
        setModalError({open: true, title: 'Error al transportar', msg: handleAxiosError(e) });
        handleCloseModal();
        setIsLoading(false);
    })
  };

  const onFetchExportPDF = (data) => {   
    const { productos, empleados, cantidades, deposito } = data;
    const { token } = JSON.parse(window.localStorage.getItem("user"));        
    const link = document.createElement('a');
    const href = `${process.env.REACT_APP_API_URL}/production/remito/download/pdf?xauthtoken=${token}&products=[${productos}]&employees=[${empleados}]&units=[${cantidades}]&deposit=${deposito}`;
    link.href = href;
    link.target = "_blank"
    link.rel = "noreferrer"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);         
};

  const refresh = () => {
    onFetchProducts();
    setTransportProducts([]);
  }

  const handleSuccess = (event, reason) => {
    if (reason === 'clickaway') return;    
    setOpenSuccess({open: false, msg: ""});
  };  

  const handleSelections = (data) => {    
    const idSplit = data.id.split('_');
    const addTransport = { 
      id: data.id, 
      id_producto: idSplit[0], 
      id_empleado: idSplit[1], 
      cantidad: data.value 
    };
    const existTp = transportProducts.findIndex(tp => tp.id === addTransport.id);
    if(existTp >= 0) {
      transportProducts[existTp].cantidad = addTransport.cantidad     
    } else {
      transportProducts.push(addTransport);
    } 

    setTransportProducts(transportProducts.filter(tp => tp.cantidad > 0));
  }

  const handleCloseError = () => {
    setModalError({open: false, title: '', msg: ''});
    refresh();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleTransportProducts = () => {
    onFetchTransportStock();
  }

  const onChangeDepositSelected = (e) => {
    setDepositSelected(e.target.value);
  }

  const nameDepositSelected = () => {
    if(deposits.length > 0){
      return deposits.find(item => item.value === depositSelect).label;
    } 
    return 'Deposito Destino';
    
  }

  return (
    <>
    <LoadBackdrop isLoading={isLoading}/>
    <Page title="Stock">
      <Container maxWidth="lg" disableGutters>      
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom pb={{ xs: 1, sm: 0 }} textAlign={{xs: "center"}}>
            Transportar {isDesktop ? "Productos desde Fabrica" : ""} 
          </Typography>     
          <TextField
            required
            id="id_deposito_destino"
            name="id_deposito_destino"
            label={'Deposito Destino'}
            margin="dense"
            value={depositSelect}
            onChange={onChangeDepositSelected}
            select
            sx={{
              width: { xs: '50%', sm: '19%' },
              marginLeft: { xs: 0, sm: 'auto' },
              marginRight: { xs: 0, sm: '19px' },
              marginBottom: { xs: 1}
            }}
          >
            {deposits.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
          </TextField>
          {rolUpdateStock && 
            <Grid>              
              <Button variant="contained" onClick={handleOpenModal} disabled={transportProducts.length < 1} sx={{marginLeft:1}} startIcon={<Iconify icon="eva:plus-fill" />}>
                Generar Remito
              </Button>    
            </Grid>
          }     
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}        
        <Card>                
          <Scrollbar>     
          <DataTableEditTemporal
            columns={StockColumns()}
            data={products}
            disableColumnMenu
            disableToolbar             
            height={650}    
            handleSelections={handleSelections} 
            density="standard"
          />
          </Scrollbar>          
        </Card>      
        <Snackbar 
          open={openSuccess.open} 
          autoHideDuration={1500} 
          onClose={handleSuccess} 
          anchorOrigin={{ 
            vertical: 'bottom', 
            horizontal: 'right' 
          }}
        >
          <Alert onClose={handleSuccess} severity="success" >
            {openSuccess.msg}
          </Alert>
        </Snackbar> 
      </Container>  
      <Dialog
            open={openModal}
            onClose={handleCloseModal}
            onBackdropClick={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Generar remito interno"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`¿Desea realizar el movimiento de las unidades de Fabrica a ${nameDepositSelected()}?`}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    {"Su confirmación genera un remito interno para transportar las unidades seleccionadas y su movimiento en el sistema, el cual no se podra deshacer"}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" disabled={isLoading} onClick={handleTransportProducts}>
                    Aceptar
                </Button>
                <Button variant="outlet" color="secondary" onClick={handleCloseModal}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={errorModal.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {errorModal.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {errorModal.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>      
    </Page>   
    </>
  );
}
