
import { useState, useEffect, useCallback } from 'react';
import { Divider, Grid, Button, Chip, Box, Card, Stack, Container, Typography, Alert,Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import axios, { handleAxiosError } from '../config/axios';
import CheckboxGroup from "../components/CheckboxGroup";
import RadioButtonGroup from "../components/RadioButtonGroup";

const extension = [
  {
      "value": "pdf",
      "label": "PDF"
  },
  {
      "value": "xlsx",
      "label": "EXCEL"
  },
];

export default function Export() {  
  const [error, setError] = useState(false);
  
  const [types, setTypes] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [classes, setClasses] = useState([]);

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState([])
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState('pdf')
  
  const [filters, setFilters] = useState(`&type=[]&subtype=[]&classes=[]&unit=[]&state=[]&ext=pdf`)

  const [expanded, setExpanded] = useState(false);
  const { token } = JSON.parse(window.localStorage.getItem("user"));

  const onFetchCategorys = useCallback(() => {    
    const instance = { 
      method: 'get', 
      url: '/categories',
      headers: {
        "x-auth-token": token,
      },
    };
    axios(instance)
      .then((data) => {
        if(data.data.ok){
          setTypes(data.data.data.tipos);
          setSubtypes(data.data.data.subtipos);
          setClasses(data.data.data.clases);
        } else {
          setError("Hay inconvientes en el servidor. Intente más tarde o comuniquese con soporte");
        }
      })
      .catch((e) => {
        setError(handleAxiosError(e));
      })
  }, [token]);

  useEffect(()=> {
    onFetchCategorys();
  }, [onFetchCategorys])

  const handleTypesChange = (options) => {
    setSelectedTypes(options);
  };
  const handleSubTypesChange = (options) => {
    setSelectedSubTypes(options);
  };
  const handleClassesChange = (options) => {
    setSelectedClasses(options);
  };

  const handleExtensionChange = (ext) => {
    setSelectedExtension(ext);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };  

  const handleFilters = () => {
    setFilters(`&type=[${selectedTypes.map(obj => obj.value)}]&subtype=[${selectedSubTypes.map(obj => obj.value)}]&classes=[${selectedClasses.map(obj => obj.value)}]&unit=[]&state=[]&ext=${selectedExtension}`);
  };  

  const handleDispTejido= () => {
    setFilters(`&type=[1]&subtype=[]&classes=[]&unit=[]&state=[1]&ext=${selectedExtension}`);
  };  

  const handleDispPostePlaca= () => {
    setFilters(`&type=[2,3]&subtype=[]&classes=[]&unit=[]&state=[1]&ext=${selectedExtension}`);
  };  

  return (
    <>
    <Page title="Exportar">
      <Container maxWidth="xl" disableGutters>      
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom pb={{ xs: 1, sm: 0 }}>
            Exportar
          </Typography>     
          <RadioButtonGroup 
            row
            options={extension} 
            onChange={handleExtensionChange} 
            defaultValue={selectedExtension} 
          />    
        </Stack>        
        <Divider>Plantillas</Divider>
        <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>        
          <Grid mb={2}>
            <Button 
              variant="outlined" 
              target="_blank"
              onClick={handleDispTejido}
              href={`${process.env.REACT_APP_API_URL}/product/download/filter?xauthtoken=${token}${filters}`}
            >
              Tejido Disponible
            </Button>                   
            <Button 
              variant="outlined" 
              target="_blank"
              onClick={handleDispPostePlaca}
              href={`${process.env.REACT_APP_API_URL}/product/download/filter?xauthtoken=${token}${filters}`}
              sx={{marginLeft:1}} 
            >
              PostePlaca Disponible
            </Button>    
          </Grid>       
        </Stack>
        <Divider>Personalizado</Divider>        
        <Stack direction="row" spacing={1} ml={1} mb={1} mt={1} maxWidth="xl" sx={{ flexWrap: "wrap", gap:1 }}  > 
          {selectedTypes.map((item, index) =>
            <Chip key={index} label={item.label} color="primary" variant="outlined"/>                
          )}
          {selectedSubTypes.map((item, index) =>
            <Chip key={index} label={item.label} color="warning" variant="outlined"/>                
          )}
          {selectedClasses.map((item, index) =>
            <Chip key={index} label={item.label} color="error" variant="outlined"/>                
          )}
        </Stack>                     
        <Card>                
          <Scrollbar>     
            <Paper>
            <Box>     
              <Accordion expanded={expanded === 'type'} onChange={handleChange('type')} defaultExpanded sx={{ m: 0 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="typebh-header" sx={{ m: 0, pt: 0, pb: 0 }}>
                  <Typography>
                    Tipo de producto
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ m: 0, pt: 0, pb: 0 }}>
                  <CheckboxGroup
                    options={types}
                    labelPlacement={'end'}
                    onChange={handleTypesChange}
                  />
                </AccordionDetails>
              </Accordion>          
              <Accordion expanded={expanded === 'subtype'} onChange={handleChange('subtype')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="subtypebh-content" id="subtypebh-header">
                  <Typography>
                    Subtipo de producto
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CheckboxGroup
                    options={(selectedTypes.length > 0) ? subtypes.filter(option => selectedTypes.map(types => types.value).includes(option.parent_value)) : subtypes}
                    labelPlacement={'end'}
                    onChange={handleSubTypesChange}
                  />
                </AccordionDetails>
              </Accordion>          
              <Accordion expanded={expanded === 'classes'} onChange={handleChange('classes')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="classesbh-content" id="classesbh-header">
                  <Typography>
                    Clase de producto
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CheckboxGroup
                    options={classes}
                    labelPlacement={'end'}
                    onChange={handleClassesChange}
                  />
                </AccordionDetails>
              </Accordion>          
              </Box>
            </Paper>
          </Scrollbar>          
        </Card>  
        <Stack direction="row" alignItems="center" justifyContent="end" mt={2} mb={2}>
          <Button 
            variant="contained" 
            startIcon={<Iconify icon="ic:file-download"/>}
            target="_blank"
            onClick={handleFilters}
            href={`${process.env.REACT_APP_API_URL}/product/download/filter?xauthtoken=${token}${filters}`}
          >
            Descargar
          </Button>                   
        </Stack>
        { error && <Alert severity="error" style={{marginTop: "20px"}} >{error}</Alert>}                             
      </Container>        
    </Page>   
    </>
  );
}
