import { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar, esES  } from '@mui/x-data-grid';

DataTableEditTemporal.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableColumnMenu: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  disableFooter: PropTypes.bool,
  styleHeader: PropTypes.object,
  height: PropTypes.number.isRequired,
  handleSelections: PropTypes.func,
  isCellEditable: PropTypes.func,
  density: PropTypes.string,
};

export default function DataTableEditTemporal(props) {

  const [pageSize, setPageSize] = useState(100);

  const dataCount = props.data.length;

  const onEdit = (data) => {
    props.handleSelections(data)
  }

  return (
    <div style={{ height: props.height, width: '100%' }}>
      <DataGrid
        rows={props.data}
        columns={props.columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 100}
        disableColumnMenu={props.disableColumnMenu}
        hideFooter={props.disableFooter}
        components={{ Toolbar: (props.disableToolbar) ? null : GridToolbar }}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, dataCount]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        density={props.density}
        sx={{
          "& .MuiDataGrid-columnHeaders": props.styleHeader,
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '1px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        onCellEditCommit={onEdit}
        isCellEditable={props.isCellEditable}
      />
    </div>
  );
}