/* eslint-disable react/prop-types */
import { Button, Typography, Zoom, Tooltip } from '@mui/material';
import { dateToDatesWeek } from '../../../utils/helpers';

export default (products, date, transferToStock, handlePp) => {
  const datesWeek = dateToDatesWeek(date);

  const detailByDay = (date, idProduct) => {
    if(!transferToStock.length) return null;
    return transferToStock.filter(pp => pp.FECHA_INICIO_PRODUCCION === date && pp.ID_PRODUCTO === idProduct);
  };

  const tooltipEmployee = (details) => {
    let text = '';
    details.forEach(detail => {
      text+= `${detail.NOMBRE_COMPLETO}\n`;
    })
    return text;
  };

  const complete = (details, value) => {
    const acum = (details.length > 1) ? details.reduce((acumulador, actual) => acumulador + actual.A_TRASPASAR, 0) : details[0].A_TRASPASAR
    return value === acum;
  }
  
  const tooltipCompleteText = (cell) => (
    <Tooltip 
      title={cell.getValue()} 
      TransitionComponent={Zoom} 
      placement="top" 
      arrow 
      describeChild 
      enterDelay={2500} 
      leaveDelay={100}
      
    >
      <Typography 
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {cell.getValue()}
      </Typography>
    </Tooltip>    
  );
  
  const tooltipDistributionStock = (cell, row, state, color = null) => (
    <Tooltip 
      title={`
        Fabrica:${row.original[`STOCK_${state}_FABRICA`]} | 
        Domenech:${row.original[`STOCK_${state}_DOMENECH`]} |
        C3:${row.original[`STOCK_${state}_C3`]}
      `} 
      TransitionComponent={Zoom} 
      placement="top" 
      arrow 
      describeChild 
      enterDelay={1000} 
      leaveDelay={100} 
    >
      <Typography align="center" color={color}>{cell.getValue()}</Typography>
    </Tooltip>    
  );
  
  const text = (cell, color = null) => <Typography align="center" color={color}>{cell.getValue()}</Typography>;
  
  const pp = (cell, details, color = null) => {    
    const border = {
      border: "dotted", 
      borderRadius: '13px', 
      borderWidth: '1.2px',
    }
    let tooltip = '';
    if(details === null || details.length === 0){
      tooltip = (cell.getValue()) ? 'Sin selección' : 'Sin carga'
      border.border = 'none';
    } else if(details.length > 0){
      tooltip = tooltipEmployee(details);
      border.border = (complete(details,cell.getValue())) ? 'solid' : 'dashed';
    }    
    return(
      <Tooltip 
        title={`${tooltip}`} 
        TransitionComponent={Zoom} 
        placement="top" 
        arrow 
        describeChild 
        enterDelay={1000} 
        leaveDelay={100}         
      >
      <Button variant="text" sx={{...border}} onClick={() => handlePp(cell)}>
        <Typography align="center" color={color}>
          {cell.getValue()}
        </Typography>
      </Button>
    </Tooltip>
  )};

  const totalFooter = (item, color = null) => (
    <Typography align="center" color={color} mt={0.9}>
      {products.reduce((acc, curr) => acc + curr[item], 0)}
    </Typography>
  );

  const buttonTotalFooter = (item, color = null) => (
    <Button variant="text">
      <Typography align="center" color={color}>
        {products.reduce((acc, curr) => acc + curr[item], 0)}
      </Typography>
    </Button>
  )

  const nameFooter = () => (
    <Typography align="right" mt={0.8}>
      Totales
    </Typography>
  );

  return [
    { 
      accessorKey: 'ID_PRODUCTO', 
      header: 'Id', 
      size: 1,
      enableClickToCopy: false,
      enableColumnDragging: false,
      enableGrouping: false,
      enableColumnFilter: false,
      enableEditing: false,
      muiTableHeadCellFilterTextFieldProps: { placeholder: '' },
      Cell: ({ cell }) => text(cell),
    },
    { 
      accessorKey: 'NOMBRE', 
      header: 'Nombre', 
      size: 20,
      minSize: 10,
      maxSize: 30,
      enableGrouping: false,
      enableClickToCopy: false,   
      enableEditing: false, 
      muiTableHeadCellFilterTextFieldProps: { placeholder: ''  },
      Cell: ({ cell }) => tooltipCompleteText(cell),
      Footer: () => nameFooter(),
    },
    { 
      accessorKey: 'LUNES', 
      meta: datesWeek.LUNES.iso, 
      header: datesWeek.LUNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell}) => pp(cell, detailByDay(datesWeek.LUNES.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('LUNES'),
    },
    { 
      accessorKey: 'MARTES', 
      meta: datesWeek.MARTES.iso,
      header: datesWeek.MARTES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.MARTES.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('MARTES'),
    },
    { 
      accessorKey: 'MIERCOLES', 
      meta: datesWeek.MIERCOLES.iso,
      header: datesWeek.MIERCOLES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.MIERCOLES.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('MIERCOLES'),
    },
    { 
      accessorKey: 'JUEVES', 
      meta: datesWeek.JUEVES.iso,
      header: datesWeek.JUEVES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.JUEVES.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('JUEVES'),
    },
    { 
      accessorKey: 'VIERNES', 
      meta: datesWeek.VIERNES.iso,
      header: datesWeek.VIERNES.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.VIERNES.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('VIERNES'),
    },
    { 
      accessorKey: 'SABADO', 
      meta: datesWeek.SABADO.iso,
      header: datesWeek.SABADO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.SABADO.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('SABADO'),
    },
    { 
      accessorKey: 'DOMINGO', 
      meta: datesWeek.DOMINGO.iso,
      header: datesWeek.DOMINGO.nameDayAndNumber, 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      Cell: ({ cell }) => pp(cell, detailByDay(datesWeek.DOMINGO.iso, cell.row.original.ID_PRODUCTO)),
      Footer: () => buttonTotalFooter('DOMINGO'),
    },
    { 
      accessorKey: 'STOCK_EN_PROCESO', 
      header: 'En Proceso', 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      enableEditing: false,
      Cell: ({ cell }) => text(cell,'#FF6347'),
      Header: ({ column }) => (<div style={{ color: '#FF6347' }}>{column.columnDef.header}</div>),  
      Footer: () => totalFooter('STOCK_EN_PROCESO', '#FF6347'),
    }, 
    { 
      accessorKey: 'STOCK_TOTAL', 
      header: 'Total', 
      size: 10,
      enableClickToCopy: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableGrouping: false,
      enableEditing: false,
      Cell: ({ cell, row }) => tooltipDistributionStock(cell, row, 'TOTAL'),  
      Footer: () => totalFooter('STOCK_TOTAL'),
    },   
  ];
}