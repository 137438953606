
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';


export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop/>
      <BaseOptionChartStyle/>
      <Router/>
    </ThemeProvider>
  );
}
