import { MenuItem, Box, Paper, TextField, Divider, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Label from '../../components/Label';
import axios, { handleAxiosError } from "../../config/axios";
import DataProduct from '../../data/grid/fields/register';

RegisterMovementForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    idProduct: PropTypes.number,
    success: PropTypes.func.isRequired,
    deposits: PropTypes.arrayOf(PropTypes.object).isRequired,
    positions: PropTypes.arrayOf(PropTypes.object).isRequired,
    typesMovements: PropTypes.arrayOf(PropTypes.object).isRequired,
    typesSubMovements: PropTypes.arrayOf(PropTypes.object).isRequired,
    maxLengthIdProduct: PropTypes.number,
};

export default function RegisterMovementForm(props) {
    const {success, handleModalClose,openModal,idProduct = null,deposits,typesMovements,typesSubMovements,maxLengthIdProduct} = props;
    
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [searchedProduct, setSearchedProduct] = useState('');
    const [disabledSearchProduct, setDisabledSearchProduct] = useState(false);
    const [disabledOriginDeposit, setDisabledOriginDeposit] = useState(true);
    const [hiddenSubtypeInicial, setHiddenSubtypeInicial] = useState(false);
    const [idMovementType, setIdMovementType] = useState(0);
    const [idMovementSubtype, setIdMovementSubtype] = useState(0);    
    const [openModalOverbook, setOpenModalOverbook] = useState(false);
    const [dataForOverbook, setDataForOverbook] = useState({});
    const [error, setError] = useState({open: false, title: '', msg: ''});

    const validationSchema = Yup.object().shape({
        id_producto: Yup.number().integer().positive().min(1).max(99999).required('El código es requerido').default(idProduct ?? ''),
        cantidad: Yup.number().positive().min(0.1).max(99999999.9).required('La cantidad es requerida'),
        id_producto_movimiento_tipo: Yup.number().integer().positive().min(1).required('El tipo de movimiento es requerida'),
        id_producto_movimiento_subtipo: Yup.number().integer().positive().min(0),
        id_deposito: Yup.number().integer().positive().min(1).required('El deposito es requerida'),
        id_deposito_destino: Yup.number().integer().positive().min(1),
        id_posicion: Yup.number().integer().positive().min(1).default(1),
        observaciones: Yup.string().max(250),   
    });
    const { register, setValue, clearErrors, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });    
    const { token } = JSON.parse(window.localStorage.getItem("user"));

    const maximumFieldLength = {
        id_producto: maxLengthIdProduct,
        cantidad: 10,
        observaciones: 250,
    };   

    const handleChangeIdProduct = useCallback((id) => {
        const onFetchView = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                    setHiddenSubtypeInicial(data.data.data.STOCK_TOTAL !== 0);
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
            })
        };
        if(id === ''){
            setSearchedProduct('');
            setProduct([]);
        } else {
            setSearchedProduct(id);
            onFetchView(id);
        }
    }, [token]);

    useEffect(() => {
        if(idProduct){
            handleChangeIdProduct(idProduct);
            setDisabledSearchProduct(true);
        }
    }, [handleChangeIdProduct, idProduct]);

    const handleCloseModalOverbook = () => {
        setOpenModalOverbook(false);
    };

    const handleOpenModalOverbook = () => {
        setOpenModalOverbook(true);
    };

    const handleOverbook = () => {
        onFetchOverbook();
    };

    const onFetchOverbook = () => {    
        setIsLoading(true);
        const instance = {
            method: 'post', 
            url: '/registration/overbook',
            headers: {
                "x-auth-token": token,
            },
            data: dataForOverbook,
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();     
            } else {
                setError({open: true, title: 'No se pudo registrar el movimiento', msg: data.data.error});
                handleCloseModalOverbook();
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo registrar el movimiento', msg: handleAxiosError(e)});
            handleCloseModalOverbook();
            setIsLoading(false);
        })
    };

    const handleLimit = (e) => {
        const maxLength = maximumFieldLength[e.target.id];
        if(e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }
    };      

    const handleOnInputIdProduct = (e) => {
        handleLimit(e)
        handleChangeIdProduct(e.target.value);
    };

    const handleChangeTypeMovement = (event) => {
        const id = event.target.value;
        setIdMovementType(id)
        setIdMovementSubtype(0);
        setValue("id_producto_movimiento_subtipo", 0);
        if(id === 4){
            setDisabledOriginDeposit(false);
            setValue("id_producto_movimiento_subtipo", 18);
        } else {
            setDisabledOriginDeposit(true);
        }
    };

    const handleChangeSubTypeMovement = (event) => {
        const id = event.target.value;
        setIdMovementSubtype(id);
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = (data) => {
        onFetchRegister(data);
        clearErrors();
    };

    const onFetchRegister = (data) => {    
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: '/registration',
            headers: {
                "x-auth-token": token,
            },
            data,
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo registrar el movimiento', msg: data.data.error});
            }
        })
        .catch((e) => {
            if(e.response.data.continue_reserv){
                setDataForOverbook(data);
                handleOpenModalOverbook();
            } else {
                setError({open: true, title: 'No se pudo registrar el movimiento', msg: handleAxiosError(e)});
            }
            setIsLoading(false);
        })
    };

    const {rowsData, rowsStock, rowsStockDetailFabrica, rowsStockDetailDomenech, rowsStockDetailC3} = DataProduct(product);

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'xl'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Registrar Movimiento (Manual)    
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={0}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        value={searchedProduct}
                                        disabled={disabledSearchProduct}
                                        id="id_producto"
                                        name="id_producto"
                                        label="Código"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        onInput={handleOnInputIdProduct}
                                        {...register('id_producto')}
                                        error={!!errors.id_producto}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="cantidad"
                                        name="cantidad"
                                        label="Cantidad"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        onInput={handleLimit}
                                        {...register('cantidad')}
                                        error={!!errors.cantidad}
                                    />
                                </Grid>                               
                                <Grid container columnSpacing={1}>                               
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="id_producto_movimiento_tipo"
                                            name="id_producto_movimiento_tipo"
                                            label="Tipo"
                                            fullWidth
                                            margin="dense"
                                            defaultValue={0}
                                            value={idMovementType}
                                            {...register('id_producto_movimiento_tipo')}
                                            error={!!errors.id_producto_movimiento_tipo}
                                            select
                                            onChange={handleChangeTypeMovement}                                            
                                        >
                                            {typesMovements.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            
                                            )}                       
                                        </TextField>                                       
                                    </Grid>
                                    {disabledOriginDeposit && 
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="id_producto_movimiento_subtipo"
                                            name="id_producto_movimiento_subtipo"
                                            label="Subtipo"
                                            fullWidth
                                            margin="dense"
                                            defaultValue={0}
                                            value={idMovementSubtype}
                                            {...register('id_producto_movimiento_subtipo')}
                                            error={!!errors.id_producto_movimiento_subtipo}
                                            disabled={!disabledOriginDeposit}
                                            select
                                            onChange={handleChangeSubTypeMovement}
                                        >
                                            {typesSubMovements
                                                .filter(option => (option.parent_value === idMovementType || option.value === 0 ) && (!hiddenSubtypeInicial || option.value !== 1 ))
                                                .map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                )                                            
                                            )}                                                        
                                        </TextField>
                                    </Grid>}
                                </Grid>
                                <Grid container columnSpacing={1}>  
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="id_deposito"
                                            name="id_deposito"
                                            label={`Deposito${(disabledOriginDeposit)?'':' Origen'}`}
                                            fullWidth
                                            margin="dense"
                                            defaultValue={1}
                                            {...register("id_deposito")}
                                            error={!!errors.id_deposito}
                                            select
                                        >
                                            {deposits.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>          
                                   {!disabledOriginDeposit &&
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="id_deposito_destino"
                                            name="id_deposito_destino"
                                            label="Deposito Destino"
                                            fullWidth
                                            margin="dense"
                                            defaultValue={2}
                                            {...register("id_deposito_destino")}
                                            error={!!errors.id_deposito_destino}
                                            disabled={disabledOriginDeposit}
                                            select
                                        >
                                            {deposits.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>} 
                                    <Grid item xs={12} sm={12}>
                                    <TextField                      
                                        id="observaciones"
                                        name="observaciones"
                                        label="Observaciones"
                                        fullWidth
                                        margin="dense"
                                        onInput={handleLimit}
                                        {...register('observaciones')}
                                        error={!!errors.observaciones}
                                        multiline/>
                                </Grid>  
                                    <Grid item xs={12} sm={12} mb={1} mt={1}>
                                        <Divider/>
                                    </Grid>   
                                </Grid> 
                                
                            </Grid>   
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsData
                                    .filter(item => item.hidden === undefined || item.hidden === true)
                                    .map((item, index) => (
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                                {item.field}                                        
                                            </Typography>
                                        </Grid>
                                    ))
                                }                            
                            </Grid>
                        </Grid>                         
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsData
                                    .filter(item => item.hidden === undefined || item.hidden === true)
                                    .map((item, index) => (
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    justifyContent: 'left',
                                                    textTransform: 'uppercase',
                                                    width: 220,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>)
                                    )}                            
                            </Grid>  
                        </Grid>                         
                        <Grid item ml={2} mr={2}>
                            <Divider orientation="vertical"/>
                        </Grid>               
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsStock.map((item, index) => 
                                    <Grid item xs={12} sm={1} key={index}>
                                        <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                            {item.field}                                   
                                        </Typography>
                                    </Grid>
                                )}                            
                            </Grid>
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStock.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="primary"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailFabrica.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailDomenech.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>                             
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailC3.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>                             
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={1}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary"  disabled={isLoading} onClick={handleSubmit(onSubmit)} >
                        Registrar
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>             
        <Dialog
            open={openModalOverbook}
            onClose={handleCloseModalOverbook}
            onBackdropClick={handleCloseModalOverbook}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Registrar reserva sin disponibilidad fisica"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"¿Desea realizar el movimiento aunque no haya unidades (fisicas) para reservar?"}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" disabled={isLoading} onClick={handleOverbook}>
                    Aceptar
                </Button>
                <Button variant="outlet" color="secondary" onClick={handleCloseModalOverbook}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
