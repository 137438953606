export default () => [
  { 
    field: 'FECHA', 
    headerName: 'Fecha',   
    width: 165,  
    headerClassName: 'super-app-theme--header',
  },
  { 
    field: 'PRODUCTO_MOVIMIENTO_TIPO', 
    headerName: 'Tipo', 
    width: 100,
  },
  { 
    field: 'PRODUCTO_MOVIMIENTO_SUBTIPO', 
    headerName: 'Movimiento', 
    width: 180,
  },
  { 
    field: 'CANTIDAD', 
    headerName: 'Cantidad', 
    width: 100,
    type: 'number',
    description: 'Cantidad de stock modificada',
  },
  { 
    field: 'STOCK_ANTERIOR', 
    headerName: 'Anterior', 
    width: 100,
    type: 'number',
    sortable: false,
    description: 'Stock disponible antes de la operación',
  },
  { 
    field: 'STOCK_POSTERIOR', 
    headerName: 'Total', 
    width: 100,
    type: 'number',
    sortable: false,
    description: 'Stock disponible despues de la operación',
  },
  { 
    field: 'OBSERVACIONES', 
    headerName: 'Observaciones', 
    flex: 0.3,
    minWidth: 200,
    sortable: false,
  },
 
];
