import { InputAdornment, MenuItem, Box, Paper, TextField, Divider, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState  } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios, { handleAxiosError } from "../../config/axios";
import Label from '../../components/Label';

AddProductForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    idProduct: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
    subtypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.arrayOf(PropTypes.object).isRequired,
    unitsMeasure: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function AddProductForm(props) {
    const {handleModalClose,openModal,refresh,idProduct,types,subtypes,classes,unitsMeasure} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({open: false, title: '', msg: ''});
    const [addAlambre, setAddAlambre] = useState(true);
    const [addPostePlaca, setAddPostePlaca] = useState(false);
    const [idProductType, setIdProductType] = useState(1);
    const [idProductSubtype, setIdProductSubtype] = useState(1);    
    const validationSchema = Yup.object().shape({
        nombre: Yup.string().min(1).max(200).required('El nombre es requerido'),
        id_producto_clase: Yup.number().integer().positive().min(1).required('La clase es requerida'),
        id_producto_tipo: Yup.number().integer().positive().min(1).required('El tipo es requerido'),
        id_producto_subtipo: Yup.number().integer().positive().min(1).required('El subtipo es requerido'),
        id_producto_unidad_medida: Yup.number().integer().positive().min(1).required('La unidad de medida es requerida'),
        rombo: Yup.string().max(20),
        calibre: Yup.string().max(20),
        altura: Yup.string().max(20),
        kg_x_rollo: Yup.string().max(20),
        kg_x_unidad: Yup.string().max(20),
        descripcion: Yup.string().max(250),   
    });
    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { token } = JSON.parse(window.localStorage.getItem("user"));

    const handleChangeTypeProduct = (event) => {
        const id = event.target.value;
        setIdProductType(id)
        setIdProductSubtype(0)
        setValue("id_producto_subtipo", 0);
        setAddAlambre(id === 1);
        setAddPostePlaca(id === 2 || id === 3);
    };

    const handleChangeSubTypeProduct = (event) => {
        setIdProductSubtype(event.target.value)
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = (data) => {
        data.id_producto = idProduct;
        onFetchCreate(data);
        reset();
    };

    const onFetchCreate = (data) => {    
        setIsLoading(true);
        const instance = { 
            method: 'post', 
            url: '/product',
            headers: {
            "x-auth-token": token,
            },
            data,
        };
        axios(instance).then((data) => {
            if(data.data.ok){  
                refresh();  
            } else {
                setError({open: true, title: 'No se pudo agregar el producto', msg: data.data.error});
            }
            setIsLoading(false);
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo agregar el producto', msg: handleAxiosError(e)});
            setIsLoading(false);
        })
    };

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
        <Grid container spacing={1} >
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1 }}>
                        Agregar Producto
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={1} py={0}>                
                    <Grid container spacing={1}>    
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="nombre"
                                name="nombre"
                                label="Nombre"
                                fullWidth
                                margin="dense"
                                {...register('nombre')}
                                error={!!errors.nombre}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="id_producto_clase"
                                name="id_producto_clase"
                                label="Clase"
                                fullWidth
                                margin="dense"
                                defaultValue={1}
                                {...register("id_producto_clase")}
                                error={!!errors.id_producto_clase}
                                select
                            >
                                {classes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>                                        
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="id_producto_tipo"
                                name="id_producto_tipo"
                                label="Tipo"
                                fullWidth
                                margin="dense"
                                defaultValue={0}
                                value={idProductType}
                                {...register('id_producto_tipo')}
                                error={!!errors.id_producto_tipo}
                                select
                                onChange={handleChangeTypeProduct}                                
                            >
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}                      
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="id_producto_subtipo"
                                name="id_producto_subtipo"
                                label="Subtipo"
                                fullWidth
                                margin="dense"
                                defaultValue={0}
                                value={idProductSubtype}
                                {...register('id_producto_subtipo')}
                                error={!!errors.id_producto_subtipo}
                                select
                                onChange={handleChangeSubTypeProduct}
                            >
                                {subtypes
                                    .filter(option => option.parent_value === idProductType || option.value === 0)
                                    .map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                }                                                        
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="id_producto_unidad_medida"
                                name="id_producto_unidad_medida"
                                label="Unidad Medida"
                                fullWidth
                                margin="dense"
                                defaultValue={1}
                                {...register('id_producto_unidad_medida')}
                                error={!!errors.id_producto_unidad_medida}
                                select
                            >
                                {unitsMeasure.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {addAlambre &&
                        <>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="rombo"
                                    name="rombo"
                                    label="Rombo"
                                    fullWidth
                                    margin="dense"
                                    helperText="Debe empezar con un número"
                                    {...register('rombo')}
                                    error={!!errors.rombo} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="calibre"
                                    name="calibre"
                                    label="Calibre"
                                    fullWidth
                                    margin="dense"
                                    helperText="Seguir formato: 14 ó 14 1/2"
                                    {...register('calibre')}
                                    error={!!errors.calibre} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="altura"
                                    name="altura"
                                    label="Altura"
                                    fullWidth
                                    margin="dense"
                                    helperText="Seguir formato: 1.00"
                                    {...register('altura')}
                                    error={!!errors.altura} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="kg_x_rollo"
                                    name="kg_x_rollo"
                                    label="Kg por Rollo"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                    }}
                                    helperText="Seguir formato: 10.50"
                                    {...register('kg_x_rollo')}
                                    error={!!errors.kg_x_rollo} />
                            </Grid>
                        </>
                        }                        
                        {addPostePlaca &&
                        <>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>                           
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="kg_x_unidad"
                                    name="kg_x_unidad"
                                    label="Kg por Unidad"
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                    }}
                                    helperText="Seguir formato: 10 ó 10.50"
                                    {...register('kg_x_unidad')}
                                    error={!!errors.kg_x_unidad} />
                            </Grid>
                        </>
                        }
                        <Grid item xs={12} sm={12}>
                            <Divider/>
                        </Grid>                        
                        <Grid item xs={12} sm={12}>
                            <TextField                      
                                id="descripcion"
                                name="descripcion"
                                label="Descripción"
                                fullWidth
                                margin="dense"
                                {...register('descripcion')}
                                error={!!errors.descripcion}
                                multiline/>
                        </Grid>      
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>
            <Grid container mr={3} ml={3} mb={1}>
                <Grid item xs={12} sm={4} align="left"> 
                    <Label
                        variant="filled"
                        color="default"
                        sx={{
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            width: 100,
                        }}
                    >
                        Código {idProduct}
                    </Label>
                </Grid>
                <Grid item xs={12} sm={8} align="right"> 
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={handleSubmit(onSubmit)} >
                        Agregar
                    </Button>
                    <Button variant="outlet" color="primary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>
        </DialogActions>             
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    </Dialog> 
    );
}
