export default () => [
   { 
    field: 'PRODUCTO', 
    headerName: 'Producto', 
    width: 160,
    type: 'number',
    description: 'Producto',
  },
   { 
    field: 'FECHA_INICIO_PRODUCCION', 
    headerName: 'Inicio', 
    width: 95,
    description: 'Fecha inicio de producción',
  },
  { 
    field: 'NOMBRE_COMPLETO', 
    headerName: 'Empleado', 
    width: 435,
    description: 'Empleado productor',
  }, 
  { 
    field: 'A_TRASPASAR', 
    headerName: 'A traspasar', 
    width: 108,
    type: 'number',
    description: 'Cantidad a traspasar',    
  },
];

