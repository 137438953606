// component
import Iconify from '../../components/Iconify';

 

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Home',
    path: '/dashboard/app',
    icon: getIcon('icon-park-outline:home'),    
  },
  {
    title: 'Stock',
    icon: getIcon('icon-park-outline:ad-product'),
    children: [
      {
        title: 'Productos',
        path: '/dashboard/stock',
        icon: getIcon('icon-park-outline:edit-one'),
      },
      {
        title: 'Exportar',
        path: '/dashboard/export',
        icon: getIcon('icon-park-outline:download-one'),
      },
      /* {
        title: 'Tejido',
        url: '/product/download/available/alambre/xlsx',
        icon: getIcon('icon-park-outline:excel'),
        downloadFile: true,
        requiresUpdaterRol: true,
      },
      {
        title: 'Tejido',
        url: '/product/download/available/alambre/pdf',
        downloadFile: true,
        icon: getIcon('icon-park-outline:file-pdf-one'),
      },
      {
        title: 'Poste/Placa',
        url: '/product/download/available/poste_placa/xlsx',
        downloadFile: true,
        icon: getIcon('icon-park-outline:excel'),
        requiresUpdaterRol: true,
      },
      {
        title: 'Poste/Placa',
        url: '/product/download/available/poste_placa/pdf',
        downloadFile: true,
        icon: getIcon('icon-park-outline:file-pdf-one'),
      }, */
    ],
  },
  {
    title: 'Producción',
    icon: getIcon('icon-park-outline:factory-building'),
    disabled: false,
    requiresAdminRol: true,
    children: [
      {
        title: 'Producción',
        path: '/dashboard/production',
        icon: getIcon('icon-park-outline:list-view')
      },      
      {
        title: 'PP Tejido',
        path: '/dashboard/pp-tejido',
        icon: getIcon('icon-park-outline:lattice-pattern')
      },
      {
        title: 'PP Poste',
        path: '/dashboard/pp-poste-placa',
        icon: getIcon('icon-park-outline:folder-one')
      },
      {
        title: 'Historial Tejido',
        path: '/dashboard/pp-history-tejido',
        icon: getIcon('icon-park-outline:chart-histogram-one')
      },
      {
        title: 'Historial Poste',
        path: '/dashboard/pp-history-poste-placa',
        icon: getIcon('icon-park-outline:chart-histogram')
      },
      {
        title: 'Transportar',
        path: '/dashboard/transport-products',
        icon: getIcon('icon-park-outline:transporter')
      },
      {
        title: 'Confirmar Remito',
        path: '/dashboard/confirm-remitos',
        icon: getIcon('icon-park-outline:file-success-one')
      },
      {
        title: 'Alertas SMD',
        path: '/dashboard/minimun-stock-excedeed',
        icon: getIcon('icon-park-outline:alarm')
      },
    ],
  },
  {
    title: 'RRHH',
    icon: getIcon('icon-park-outline:user-positioning'),
    disabled: false,
    requiresAdminRol: true,
    children: [
      {
        title: 'Empleados',
        path: '/dashboard/employee',
        icon: getIcon('icon-park-outline:worker')
      },
    ],
  },
  {
    title: 'Ventas',
    path: '/dashboard/blog',
    icon: getIcon('map:grocery-or-supermarket'),
    disabled: true,
  },
  {
    title: 'Finanzas',
    path: '/login',
    icon: getIcon('mdi:finance'),
    disabled: true,
  },
 
  /* {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  }, */
];

export default navConfig;
