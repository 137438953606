import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
  
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import Iconify from './Iconify';

 

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  index: PropTypes.number,
  isOpen: PropTypes.bool,
  onCollapseOpen: PropTypes.func,
};

function NavItem({ item, active, index, isOpen, onCollapseOpen  }) {
  const handleOpen = () => {
    setOpen((prev) => !prev);
    onCollapseOpen(index);
  };
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, disabled = false, requiresAdminRol } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const { rolAdmin, rolUpdateStock, token } = JSON.parse(window.localStorage.getItem("user"));

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
          disabled={disabled || (requiresAdminRol && !rolAdmin)}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={isOpen && open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children
              .filter(item => !(item.requiresUpdaterRol && !rolUpdateStock))
              .map((item, i) => {
                const { title, path, icon, downloadFile, url} = item;
                const isActiveSub = active(path);
                  if(downloadFile){
                    return (    
                      <a 
                        key={i}
                        href={`${process.env.REACT_APP_API_URL}${url}?xauthtoken=${token}`}
                        style={{ textDecoration: 'none'}} 
                        target="_blank" 
                        rel="noreferrer"                    
                      >
                        <ListItemStyle
                          sx={{
                            ...(isActiveSub && activeSubStyle),
                          }}
                        >
                          <ListItemIconStyle>
                            {icon && icon}
                          </ListItemIconStyle>
                          <ListItemText disableTypography primary={title} />
                        </ListItemStyle>
                      </a>
                    )
                  } 
                  return (
                    <ListItemStyle
                      key={i}
                      component={RouterLink}
                      to={path}
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                      }}
                    >
                      <ListItemIconStyle>
                        {icon && icon}
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );        
              })
            }
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      disabled={disabled || (requiresAdminRol && !rolAdmin)}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [openIndex, setOpenIndex] = useState(-1);

  const handleCollapseOpen = (index) => {
    setOpenIndex(index);
  };

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item, index) => (
          <NavItem 
            key={item.title} 
            item={item} 
            active={match} 
            index={index}
            isOpen={index === openIndex}
            onCollapseOpen={handleCollapseOpen}
          />
        ))}
      </List>
    </Box>
  );
}
