import { Box, Paper, TextField, Divider, Typography, Grid, Button, Dialog, DialogActions ,DialogContent ,DialogTitle ,DialogContentText} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState, useCallback, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import Label from '../../components/Label';
import axios, { handleAxiosError } from "../../config/axios";
import DataProduct from '../../data/grid/fields/register';
import AutoComplete from '../../components/AutoComplete';

AddPPForm.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    success: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default function AddPPForm(props) {
    const {success, handleModalClose,openModal, type} = props;
    
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [searchedById, setSearchedById] = useState('');
    const [searchedByName, setSearchedByName] = useState('');
    const [namesProduct, setNamesProduct] = useState([]);
    const [error, setError] = useState({open: false, title: '', msg: ''});
 
    const { token } = JSON.parse(window.localStorage.getItem("user"));  

    const onFetchNamesProduct = useCallback(() => {  
        setIsLoading(true);
        const instance = { 
            method: 'get', 
            url: `/product/list/names?type=[${(type==='alambre')?'1':'2,3'}]`,
            headers: {
                "x-auth-token": token,
            },
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                setNamesProduct(data.data.data);
            } else {
                setError({open: true, title: 'No se pudo obtener la lista de nombres de productos', msg: data.data.error});
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo obtener la lista de nombres de productos', msg: handleAxiosError(e)});           
            setIsLoading(false);
        })
    }, [token, type]);

    useEffect(()=> {
        onFetchNamesProduct();
    }, [onFetchNamesProduct]);

    const handleChangeIdProduct = useCallback((id) => {
        const onFetchViewById = (id) => {    
            const instance = { 
                method: 'get', 
                url: `/product/${id}?type=${type}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                    setSearchedById('');
                    setProduct([]);
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
                setSearchedById('');
                setProduct([]);
            })
        };
        onFetchViewById(id);
    }, [token, type]);

    const handleChangeNameProduct = useCallback((name) => {
        const onFetchViewByName = (name) => {    
            const instance = { 
                method: 'get', 
                url: `/product/name/${encodeURIComponent(name)}?type=${type}`,
                headers: {
                    "x-auth-token": token,
                },
            };
            axios(instance).then((data) => {
                if(data.data.ok){  
                    setProduct(data.data.data);     
                } else {
                    setError({open: true, title: 'No se pudo encontrar el producto', msg: data.data.error});
                    setSearchedByName('');
                    setProduct([]);
                }
            })
            .catch((e) => {
                setError({open: true, title: 'No se pudo encontrar el producto', msg: handleAxiosError(e)});
                setSearchedByName('');
                setProduct([]);
            })
        };
        onFetchViewByName(name);
    }, [token, type]);



    const handleSearchProduct = () => {
        if(searchedById){
            handleChangeIdProduct(searchedById)
        } 
        if(searchedByName) {
            handleChangeNameProduct(searchedByName)
        }
        
    };

    const handleOnInputIdProduct = (e) => {
        const id = e.target.value;
        if(id === ''){
            setSearchedById('');
            setProduct([]);
        } else {
            setSearchedById(id);
        }
    };

    const handleOnInputNameProduct = (name) => {
        if(name === '' || name === null){
            setSearchedByName('');
            setProduct([]);
        } else {
            setSearchedByName(name);
        }
    };

    const handleCloseError = () => {
        setError({open: false, title: '', msg: ''});
    };

    const onSubmit = () => {
        onFetchAddPP(product);
    };

    const onFetchAddPP = (product) => {    
        setIsLoading(true);
        const instance = { 
            method: 'patch', 
            url: `/production/add/${product.ID_PRODUCTO}`,
            headers: {
                "x-auth-token": token,
            },
        };
        axios(instance).then((data) => {
            setIsLoading(false);
            if(data.data.ok){  
                success();
            } else {
                setError({open: true, title: 'No se pudo añadir el producto a la lista', msg: data.data.error});
            }
        })
        .catch((e) => {
            setError({open: true, title: 'No se pudo añadir el producto a la lista', msg: handleAxiosError(e)});           
            setIsLoading(false);
        })
    };

    const {rowsData, rowsStock, rowsStockDetailFabrica, rowsStockDetailDomenech} = DataProduct(product);

    return (
        <Dialog open={openModal} onClose={()=>handleModalClose} fullWidth maxWidth={'md'} onBackdropClick={handleModalClose}>
        <DialogTitle>
            <Grid container spacing={1}>                
                <Grid item xs={12} sm={11} mt={1}>
                    <Typography variant="title" align="left" margin="dense" sx={{ color: 'text.primary', marginLeft: 1, }}>
                        Añadir Producto   
                    </Typography>  
                </Grid>
                <Grid item xs={12} sm={1} align="right">
                    <Button onClick={handleModalClose} sx={{padding:0, margin:0}}>
                        <CloseOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>    
            <Paper>            
                <Box px={3} py={0}>                
                    <Grid container  spacing={0} rowSpacing={1} columnSpacing={1}  justifyContent="flex-start">  
                        <Grid item xs={12} sm={12}>   
                            <Grid container alignItems="flex-start" spacing={1} >
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        disabled={searchedByName !== ''}
                                        value={searchedById}
                                        id="id_producto"
                                        name="id_producto"
                                        label="Código"
                                        fullWidth
                                        type="number"
                                        margin="dense"
                                        onInput={handleOnInputIdProduct}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <AutoComplete
                                        label='Nombre'
                                        options={namesProduct}
                                        onChange={handleOnInputNameProduct}
                                        disabled={searchedById !== ''}
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={1}>
                                    <Button variant="contained" color="primary"  disabled={searchedByName === '' && searchedById === ''}  sx={{marginTop:1, padding:2}} onClick={handleSearchProduct} >
                                        <SearchIcon color='secondary' fontSize='medium'/>
                                    </Button>
                                </Grid> 
                                <Grid container columnSpacing={1}>       
                                    <Grid item xs={12} sm={12} mb={1} mt={1}>
                                        <Divider/>
                                    </Grid>   
                                </Grid>                                 
                            </Grid>   
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsData
                                    .filter(item => item.hidden === undefined || item.hidden === true)
                                    .map((item, index) => (
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                                {item.field}                                        
                                            </Typography>
                                        </Grid>
                                    ))
                                }                            
                            </Grid>
                        </Grid>                         
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsData
                                    .filter(item => item.hidden === undefined || item.hidden === true)
                                    .map((item, index) => (
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    justifyContent: 'left',
                                                    textTransform: 'uppercase',
                                                    width: 220,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>)
                                    )}                            
                            </Grid>  
                        </Grid>                         
                        <Grid item ml={2} mr={2}>
                            <Divider orientation="vertical"/>
                        </Grid>               
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                {rowsStock.map((item, index) => 
                                    <Grid item xs={12} sm={1} key={index}>
                                        <Typography variant="subtitle1" align="left" sx={{ color: 'text.secondary', marginLeft: item.marginLeft }}>
                                            {item.field}                                   
                                        </Typography>
                                    </Grid>
                                )}                            
                            </Grid>
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStock.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="primary"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailFabrica.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>  
                        <Grid item>      
                            <Grid container direction="column" alignItems="flex-start" rowSpacing={1} >
                                    {rowsStockDetailDomenech.map((item, index) => 
                                        <Grid item xs={12} sm={1} key={index}>
                                            <Label
                                                variant={item.variant ?? "filled"}
                                                color="default"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    width: 100,
                                                }}
                                            >
                                                {item.startAdornment ?? ""} {item.value ?? item.default} {item.endAdornment ?? ""}
                                            </Label>
                                        </Grid>
                                    )}                            
                            </Grid>  
                        </Grid>                             
                    </Grid>          
                </Box>
            </Paper>            
        </DialogContent>     
        <DialogActions>            
            <Grid container mr={3} ml={3} mb={1} mt={1}>    
                <Grid item xs={12} sm={12} align="right">  
                    <Button variant="contained" color="primary"  disabled={isLoading || product.length < 1} onClick={onSubmit} >
                        Añadir
                    </Button>
                    <Button variant="outlet" color="secondary" onClick={handleModalClose} sx={{marginLeft:1}}>
                        Cancelar
                    </Button> 
                </Grid>
            </Grid>         
        </DialogActions>          
        <Dialog
            open={error.open}
            onClose={handleCloseError}
            onBackdropClick={handleCloseError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {error.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error.msg}
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseError}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
        
    </Dialog> 
    );
}
