import { Tooltip, IconButton, Zoom} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export default (handleDelete) => [
   { 
    field: 'CANTIDAD', 
    headerName: 'Cantidad', 
    width: 108,
    type: 'number',
    description: 'Cantidad de producto en proceso',
  },
  { 
    field: 'NOMBRE_COMPLETO', 
    headerName: 'Empleado', 
    width: 642,
    description: 'Empleado productor',
  }, 
  { 
    field: '',
    headerName: '', 
    width: 10,
    description: 'Deshacer unidades en proceso',
    renderCell: (params) => (
      <Tooltip 
        key={params.id}
        title={'Deshacer carga de unidades'} 
        TransitionComponent={Zoom} 
        placement="top" 
        describeChild 
        enterDelay={1500} 
        leaveDelay={100} 
      >
        <IconButton onClick={() => handleDelete(params.row)}>
          <DeleteForeverOutlinedIcon color='primary' fontSize='medium'/>
        </IconButton>
      </Tooltip>
    )
  }, 
];
